import { createI18n } from "vue-i18n";

const i18n = createI18n({
    locale: 'kg',
    fallbackLocale: 'ru',
    legacy: false,
    fallbackWarn: false, // Установите параметр на true, чтобы отключить предупреждения
    missingWarn: false
});

export default i18n;