<template>
    <div class="modal modal-xl" id="liveModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header align-items-start">
                    <h1 class="modal-title fs-5"></h1>
                    <div class="modal-header-actions d-flex align-items-end">
                        <a href="#" class="btn-hide" data-bs-dismiss="modal" aria-label="Close"></a>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="stopYoutubeVideo"></button>
                    </div>
                </div>
                <div class="modal-body text-center">
                    <div class="ratio ratio-16x9">
                        <iframe class="embed-responsive-item" id="youtube-video" allow="autoplay"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import {onMounted} from "vue";

onMounted(() => {
    window.addEventListener('beforeunload', removeFromLocalStorage);
})

const stopYoutubeVideo = () => {
    let iframe = document.querySelector('#liveModal iframe');
    iframe.src = null;

    removeFromLocalStorage();
}

const removeFromLocalStorage = () => {
    localStorage.removeItem('youtube-id');
}

</script>