<template>
    <div class="section-tools p-0">
        <div class="section-tools-wrapper d-flex flex-column flex-md-row">
			<div>
                <router-link
                    :to="{name: 'radio.index', query: {type: 'publications'}}"
                    class="d-block h-100 btn btn-light flex-fill"
                    :class="{active: activeCat === 'publications'}"
					@click="emit('active-change', 'publications')">
                    {{ $t('radio.publications') }}
                </router-link>
            </div>
            <div>
                <router-link
                    :to="{name: 'radio.index', query: {type: 'news'}}"
                    class="d-block h-100 btn btn-light flex-fill"
                    :class="{active: activeCat === 'news'}"
					@click="emit('active-change', 'news')">
                    {{ $t('radio.news') }}
                </router-link>
            </div>
			<div>
                <router-link
                    :to="{name: 'radio.page', params: {page: 'frequencies'}}"
                    class="d-block h-100 btn btn-light flex-fill"
                    :class="{active: props.active === 'freqs'}">
					{{ $t('radio.freqs') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    active: {
        type: String,
        default: "publications",
        required: false
    }
});
const activeCat = ref(props.active);
const emit = defineEmits();

watch(
	() => props.active,
	(newCat) => {activeCat.value = newCat;}
);
</script>
