export default [
    {
        path: '',
        name: 'votes.calendar',
        component: () => import('@/views/vote/Calendar.vue'),
    },
    {
        path: 'list',
        name: 'votes.list',
        component: () => import('@/views/vote/List.vue'),
        props: (route) => ({ pageNumber: parseInt(route.query.p) || 1 })
    },
    {
        path: ':id',
        name: 'votes.show',
        component: () => import('@/views/vote/Show.vue'),
    },
];