export default async function fetchData(host, params = {}) {
    try {
        const url = new URL(host);
        url.search = new URLSearchParams(params).toString();

        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "GET",
            mode: "cors", // no-cors, *cors, same-origin
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Request failed with status ${response.status}. ${errorText}`);
        }

        return await response.json();
    }
    catch (error) {
        console.error("fetchData error:", error);
        return null;
    }
}