<template>
    <div class="wrapper" id="the-wrapper">
        <router-view></router-view>
    </div>
</template>

<script setup>
import {nextTick, onMounted} from "vue";

onMounted(async () => {
    await nextTick(); // Ожидаем, пока Vue завершит рендеринг
    normalizeWrapperTop();
});

window.addEventListener('resize', normalizeWrapperTop);

function normalizeWrapperTop() {
    const device = getUserDevice();
    const header = document.getElementById(device + '-header');
    const wrapper = document.getElementById('the-wrapper');

    if (header && wrapper) {
        wrapper.style.marginTop = `${header.clientHeight}px`;
    }
}

function getUserDevice() {
    if (window.innerWidth >= 768) {
        return "desktop"
    } else {
        return "mobile";
    }
}

</script>