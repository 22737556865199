// Ваш middleware функция
import {getCookie, hasCookie} from "@/helpers/cookieAssistant";

async function isUserExists(userInfo) {
    const USER_EXIST = API_SERVER + "/esi_users/exist";

    try {
        const response = await fetch(USER_EXIST + '/'  + userInfo.pin, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "GET",
            mode: "cors", // no-cors, *cors, same-origin,
        });

        if (!response.ok) {
            throw new Error('USER_EXIST: status_code !== 200');
        }

        return await response.json();
    }
    catch (error) {
        console.error('USER_EXIST error:', error)
        return undefined;
    }
}


export default function authorized(to, from, next) {

    // если пользователь авторзован перенаправить на user/profile
    (function() {
        let userdata = null;

        // 1.Проверяем наличие cookie с именем 'userdata'
        if (!hasCookie('userdata')) {
           return
        }

        // 2.Проверяем что в 'userdata' лежат валидные данные формата JSON
        try {
            userdata = JSON.parse(getCookie('userdata', true));
        } catch {
            return
        }

        // 3.Проверяем наличие в БД сервере такого пользователя
        try {
            if (!isUserExists(userdata)){
                return;
            }
        } catch {
            return;
        }

        // 4.Проверяем дал ли пользователь разрешение на обраюотку данных ESI
        if (!hasCookie('esi_permit')) {
            return;
        }

        // если пользователь авторизван то перенаправить на profile
        if (authorized) {
            next({ name: 'user.profile' });
        }
    })();

    // иначе продолжить(авторизоваться)
    next()
}