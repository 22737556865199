import fetchApiData from "@/store/modules/search/actions/fetchApiData";
import sections from "@/store/modules/search/data/sections";

const state = {
    posts: [],
    pagination: {
        total: 0,
        page: 1,
        limit: 9
    },
    loading: false,
    keyword: ""
};

const mutations = {
    SET_POSTS(state, posts) {
        if (Array.isArray(posts)) {
            state.posts = posts;
        }
    },
    SET_PAGINATION_TOTAL(state, total) {
        if (typeof total === 'number') {
            state.pagination.total = total;
        }
    },
    SET_PAGINATION_PAGE(state, page) {
        if (typeof page === 'number') {
            state.pagination.page = page;
        }
    },
    SET_KEYWORD(state, keyword) {
        if (typeof keyword === 'string') {
            state.keyword = keyword;
        }
    },
    RESET_PAGINATION(state) {
        state.posts = [];
        state.pagination.page = 1;
        state.pagination.total = 0;
        state.pagination.limit = 9;
    },
};

const actions = {
    async fetchNews({ commit, state }) {
        const options = {
            commit,
            state,
            url: API_SERVER + "/posts",
            sortBy: "publishDate",
            sortDirection: "desc",
        };
        await fetchApiData(options);
    },

    async fetchAgenda({ commit, state }) {
        const options = {
            commit,
            state,
            url: API_SERVER + "/calendars",
            sortBy: "meetingDate",
            sortDirection: "desc",
        };
        await fetchApiData(options);
    },

    async fetchBills({ commit, state }) {
        const options = {
            commit,
            state,
            url: SED_SERVER + "/docs"
        };

        await fetchApiData(options);
    },

    async fetchBillsPublic({ commit, state }) {
        const options = {
            commit,
            state,
            url: API_SERVER + "/laws",
            sortBy: "publishDate",
            sortDirection: "desc",
        };
        await fetchApiData(options);
    },

    async fetchDeputies({ commit, state }) {
        const options = {
            commit,
            state,
            url: API_SERVER + "/deputies",
            sortBy: "lastName",
            sortDirection: "asc",
        };
        await fetchApiData(options);
    },

    setPage({ commit }, newPage) {
        commit("SET_PAGINATION_PAGE", newPage);
    },
};

const getters = {
    getSections: () => sections,
    getKeyword: () => state.keyword,
    getPosts: state => state.posts,
    getPaginationTotal: state => state.pagination.total,
    getPaginationPage: state => state.pagination.page,
    getPaginationLimit: state => state.pagination.limit
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};