/**
 * Получает значение cookie по имени.
 *
 * @param {string} name - Имя cookie.
 * @param {boolean} [decode=false] - Указывает, нужно ли декодировать значение cookie.
 * @returns {string|undefined} - Значение cookie или undefined, если cookie с указанным именем не найдено.
 */
export function getCookie(name, decode = false) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
        return decode ? decodeURIComponent(match[2]) : match[2];
    }
}

/**
 * Устанавливает cookie с указанным именем, значением и сроком жизни.
 *
 * @param {string} name - Имя cookie.
 * @param {string} value - Значение cookie.
 * @param {number} [expirationMinutes=60] - Срок жизни cookie в минутах.
 * @param {boolean} [encode=false] - Указывает, нужно ли закодировать значение cookie.
 */
export function setCookie(name, value, expirationMinutes = 60, encode = false) {
    const date = new Date();
    date.setTime(date.getTime() + (expirationMinutes * 60 * 1000)); // Преобразование минут в миллисекунды
    const expires = "expires=" + date.toUTCString();
    const encodedValue = encode ? encodeURIComponent(value) : value;

    document.cookie = name + "=" + encodedValue + ";" + expires + ";path=/;domain=kenesh.kg";
    document.cookie = name + "=" + encodedValue + ";" + expires + ";path=/;domain=www.kenesh.kg";
}

/**
 * Проверяет наличие cookie с указанным именем.
 *
 * @param {string} name - Имя cookie.
 * @returns {boolean} - true, если cookie с указанным именем существует, иначе false.
 */
export function hasCookie(name) {
    return document.cookie.split(';').some((cookie) => {
        const trimmedCookie = cookie.trim();
        return trimmedCookie.indexOf(name + '=') === 0;
    });
}

/**
 * Удаляет cookie с указанным именем.
 *
 * @param {string} name - Имя удаляемой cookie.
 */
export function deleteCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=kenesh.kg';
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=www.kenesh.kg';
}