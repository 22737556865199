export default [
    {
        path: '',
        name: 'toraga.index',
        component: () => import('@/views/toraga/Index.vue'),
    },
    {
        path: 'news',
        name: 'toraga.news',
        component: () => import('@/views/toraga/News.vue'),
    },
    {
        path: 'photo',
        name: 'toraga.photo',
        component: () => import('@/views/toraga/Photo.vue'),
    },
    {
        path: 'video',
        name: 'toraga.video',
        component: () => import('@/views/toraga/Video.vue'),
    },
];