<template>
    <div class="page-actions">
        <div v-if="props.print" class="page-action-item action-print">
            <a @click="printPage">
                <h6>{{ $t('page.print') }}</h6>
                <PhPrinter weight="bold"/>
            </a>
        </div>
        <div v-if="props.share" class="page-action-item action-share">
            <div class="d-flex align-items-center">
                <a class="text-primary facebook" @click="shareOnFacebook">
                    <PhFacebookLogo/>
                </a>
                <a class="text-primary twitter" @click="shareOnTwitter">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0,0,256,256"
                         style="fill:#000000;">
                        <g fill="#888888" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(8.53333,8.53333)"><path d="M26.37,26l-8.795,-12.822l0.015,0.012l7.93,-9.19h-2.65l-6.46,7.48l-5.13,-7.48h-6.95l8.211,11.971l-0.001,-0.001l-8.66,10.03h2.65l7.182,-8.322l5.708,8.322zM10.23,6l12.34,18h-2.1l-12.35,-18z"></path></g></g>
                    </svg>
                </a>
                <a class="text-primary whatsapp" @click="shareOnWhatsApp">
                    <PhWhatsappLogo/>
                </a>
                <a class="text-primary telegram" @click="shareOnTelegram">
                    <PhTelegramLogo/>
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import {PhPrinter, PhFacebookLogo, PhWhatsappLogo, PhTelegramLogo} from "@phosphor-icons/vue";
import {useRoute, useRouter} from "vue-router";
import Trans from "@/i18n/translation";

const props = defineProps({
    print: {
        type: Boolean,
        default: true
    },
    share: {
        type: Boolean,
        default: true
    }
})
const emit = defineEmits();
let currentUrl = window.location.href;
const route = useRoute();
const router = useRouter();

const localizedRoute = () => {
  const locale = Trans.getPersistedLocale();
  const { path } = router.currentRoute.value;
  if(!path.includes(locale)){
    currentUrl = `${window.location.origin}/${locale}${path}`;
  }
}

localizedRoute();

const { path, query, params } = router.currentRoute.value;

const shareOnTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`, '_blank', 'width=400,height=300');
}

const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`, '_blank', 'width=400,height=300');
}

const shareOnWhatsApp = () => {
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(currentUrl)}`, '_blank');
}

const shareOnTelegram = () => {
    window.open(`tg://msg_url?url=${encodeURIComponent(currentUrl)}`, '_blank');
}

const printPage = () => {
    window.print();
}

</script>

<style scoped>
.page-actions .page-action-item {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.page-actions .page-action-item:not(:last-child) {
    margin-bottom: 10px;
}

.page-actions .action-print a{
    display: flex;
    align-items: center;
    position: relative;
    color: #888888;
}

.page-actions .action-print a:hover,
.page-actions .action-print a:active
{
    color: #888888;
}

.page-actions .action-print a:hover::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #888888;
    bottom: -3px;
    left: 0;
}

.page-actions .action-print svg{
    display: block;
    margin-left: 6px;
    font-size: 24px;
    color: #888888;
}
.page-actions .action-share a{
    position: relative;
    margin: auto 4px;
    text-align: center;
    padding: 0 2px;
}

.page-actions .action-share a:last-child{
    margin-right: 0;
}

.page-actions .action-share a:hover::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #888888;
    bottom: -3px;
    left: 0;
}

.page-actions .action-share svg{
    display: block;
    font-size: 24px;
    color: #888888;
    margin: auto;
}

</style>