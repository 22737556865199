// store/index.js

import { createStore } from 'vuex';
import search from "@/store/modules/search";
import bills from "@/store/modules/bills";
import billsPublic from "@/store/modules/bills-public";

export default createStore({
    modules: {
        bills,
        billsPublic,
        search,
    },
});