<template>
    <div class="card border-0 text-bg-dark">
        <img loading="lazy" :src="imageUrl" class="card-img" alt="...">
        <div class="card-img-overlay d-flex align-items-end">
            <div class="d-flex align-items-end">
                <PhImagesSquare v-if="props.type === 'photo'" class="flex-shrink-0" />
                <PhPlayCircle v-if="props.type === 'video'" class="flex-shrink-0" />
                <h6 class="card-title mb-0">
                    {{ props.title }}
                </h6>
                <router-link :aria-label="props.title"
                             :to="{ name: 'posts.show', params: { id: props.postId } }"
                             class="stretched-link"/>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {PhImagesSquare, PhPlayCircle} from "@phosphor-icons/vue";
    import {computed} from "vue";
    import getImagePath from "@/js/global/getImagePath";

    const props = defineProps({
        postId: {
            type: Number,
            required: true
        },
        type: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
        imageQuality: {
            type: [String, null],
            default: null,
            required: false,
            validator: (value) => {
                return value === null || ['sm', 'md', 'hd'].includes(value);
            }
        },
        imageUrl: {
            type: String,
            required: false,
        },
    });

    const imageUrl = computed(() => {
        return getImagePath(props.imageUrl, "posts", props.postId, props.imageQuality)
    });
</script>