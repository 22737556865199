export default function getScrollbarWidth() {
    // Создаем элемент-контейнер с прокруткой
    const scrollContainer = document.createElement('div');
    scrollContainer.style.width = '100px';
    scrollContainer.style.height = '100px';
    scrollContainer.style.overflow = 'scroll';
    scrollContainer.style.position = 'absolute';
    scrollContainer.style.top = '-9999px';

    // Добавляем элемент на страницу
    document.body.appendChild(scrollContainer);

    // Вычисляем ширину скроллбара
    const scrollbarWidth = scrollContainer.offsetWidth - scrollContainer.clientWidth;

    // Удаляем элемент из DOM
    document.body.removeChild(scrollContainer);

    return scrollbarWidth;
}