<template>
    <div class="section section-modules section-links print-hide">
        <div class="container">
            <div class="glide" id="site-link">
                <div class="glide__track" data-glide-el="track">
                    <ul class="glide__slides">
                        <li class="glide__slide" v-for="link in Manager.links">
                            <div class="card">
                                <div class="card-body">
                                    <h5>{{getLocaleText(link.titleKg, link.titleRu)}}</h5>
                                    <PhLinkSimple weight="light"/>
                                    <a :href="link.link" class="stretched-link"></a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <slot name="arrows"></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import '@/styles/pages/sections/modules.scss';
import '@/styles/pages/sections/links.scss';
import '@glidejs/glide/dist/css/glide.core.css';
import '@glidejs/glide/dist/css/glide.theme.css';
import Glide from "@glidejs/glide";
import {PhLinkSimple} from "@phosphor-icons/vue";

import {onMounted, reactive} from 'vue';
import fetchPaginatedData from "@/js/global/fetchPaginatedData";
import getLocaleText from "@/js/global/getLocaleText";

const Manager = {
    links: reactive([]),

    catalog: {
        async loadLinks() {
            const {data} = await Manager.api.getLinks();
            if (data.length > 0) {
                Manager.links.push(...data[0].menus);
            }
        },
    },
    api: {
        async getLinks() {
            const url = API_SERVER + "/menus";
            const params = { slug: "links" };
            return await fetchPaginatedData(url, params);
        },
    }
}

onMounted(async () => {
    await Manager.catalog.loadLinks();

    const glide = new Glide(document.getElementById('site-link'), {
        perTouch: 1,
        type: "carousel",
        swipeThreshold: true,
        dragThreshold: true,
        keyboard: true,
        breakpoints: {
            768: {
                perView: 1
            },
            992: {
                perView: 2,
            },
            4000: {
                perView: 4
            },
        }
    });
    glide.mount();
});
</script>