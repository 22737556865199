export default [
    {
        path: '',
        name: 'appeal.send',
        component: () => import("@/views/appeal/Send.vue"),
        props: (route) => ({
			auth: route.query.auth,
			deputyId: route.query.deputy
		}),
    },
    {
        path: ':id',
        name: 'appeal.show',
        component: () => import("@/views/appeal/Show.vue")
    }
]