import {getCookie, hasCookie} from "@/helpers/cookieAssistant";
import isJsonString from "@/helpers/isJsonString";

/**
 * Проверяет, авторизован ли пользователь на основе наличия и формата куки 'userdata'.
 *
 * @returns {boolean} Возвращает true, если авторизован
 *                   Возвращает false, если не авторизован
 */
export default function userAuthorized() {
    if (!hasCookie('userdata')) {
        return false;
    }

    const userInfo = getCookie('userdata', true);

    return isJsonString(userInfo);
}