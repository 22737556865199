<template>
    <div class="side-toggle print-hide">
       <a href="#"
           role="button"
           class="side-toggle-btn d-flex justify-content-center align-items-center"
           data-bs-toggle="modal"
           data-bs-target="#appealModal">
            <PhChatCenteredDots/>
            <span class="d-none d-md-inline"> {{ $t('_appeal_online')}}</span>
    	</a>
    </div>
</template>

<script setup>
import {PhChatCenteredDots} from "@phosphor-icons/vue";
import {observeClassChange} from "@/helpers/observeDom";
import {onMounted} from "vue";
import getScrollbarWidth from "@/helpers/getScrollbarWidth";

onMounted(() => {
    const sideToggle = document.querySelector('.side-toggle');
    const sideToggleRight = window.getComputedStyle(sideToggle).getPropertyValue('right');
    const rightOffset = parseInt(sideToggleRight, 10);

    observeClassChange(document.body, () =>
    {
        const modalIsOpen = document.body.classList.contains('modal-open');

        if (modalIsOpen) {
            sideToggle.style.right = rightOffset + getScrollbarWidth() + "px";
        }
        else {
            sideToggle.style.right = rightOffset + "px";
        }
    })
})
</script>