<template>
    <div class="card" :class="{'card-pinned': props.pinned}">
        <img
			loading="lazy"
			:src="imageUrl"
			class="card-img-top bvi-img"
			:class="{vertical: props.verticalOrientation}"
			alt="">
        <div class="card-body">
            <h6 class="card-subtitle">
                {{ props.publishDate }}
            </h6>
            <h5 class="card-title d-flex align-items-end">
                {{ props.title }}
                <PhPushPin v-if="props.pinned" class="flex-shrink-0" weight="bold"/>
            </h5>
            <router-link :to="{ name: 'posts.show', params: { id: props.id } }"
                         :title="props.title"
                         :aria-label="ariaLabel"
                         class="stretched-link"/>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";
import {PhPushPin} from "@phosphor-icons/vue";
import getImagePath from "@/js/global/getImagePath";

const props = defineProps({
    id: {
        type: Number,
        required: true
    },
    pinned: {
        type: Boolean,
        required: false,
        default: false
    },
    title: {
        type: String,
        required: false,
    },
    imageUrl: {
        type: [String, null],
        default: null,
        required: false,
    },
    imageQuality: {
        type: [String, null],
        default: null,
        required: false,
        validator: (value) => {
            return value === null || ['sm', 'md', 'hd'].includes(value);
        }
    },
	verticalOrientation: {
		type: Boolean,
		default: false,
		required: false
	},
    publishDate: {
        type: String,
        required: false
    },
});

const imageUrl = computed(() => {
    return getImagePath(props.imageUrl, "posts", props.id, props.imageQuality);
});

const ariaLabel = (() => {

    let result = "";

    if (props.pinned) {
        result += "Важная новость: " + props.title;
    } else {
        result += props.title;
    }

    result += ". Опубликован: " + props.publishDate;

    return result;
})();


</script>