export default function dropdownNormalize () {
    // Получаем все кнопки dropdown-toggle
    const dropdownToggles = document.querySelectorAll('.dropdown-toggle');

    // Добавляем обработчик клика на каждую кнопку dropdown-toggle
    dropdownToggles.forEach(toggle => {
        toggle.addEventListener('click', function(event) {
            // Закрываем все dropdowns, кроме текущего
            const dropdowns = document.querySelectorAll('.dropdown-menu');
            dropdowns.forEach(dropdown => {
                if (dropdown !== toggle.nextElementSibling) {
                    dropdown.classList.remove('show');
                }
            });
        });
    });

    // Добавляем обработчик клика на документ для закрытия всех dropdowns при клике вне них
    document.addEventListener('click', function(event) {
        if (!event.target.closest('.dropdown')) {
            const dropdowns = document.querySelectorAll('.dropdown-menu');
            dropdowns.forEach(dropdown => {
                dropdown.classList.remove('show');
            });
        }
    });
};