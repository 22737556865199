import { createWebHistory, createRouter } from 'vue-router';
import Trans from "@/i18n/translation";

import agendaRoutes from './agenda';
import appealRoutes from './appeal';
import billsRoutes from './bills';
import committeesRoutes from './committees';
import deputiesRoutes from './deputies';
import esiRoutes from './esi';
import factionsRoutes from './factions';
import initiativesRoutes from './initiatives';
import keneshRoutes from './kenesh';
import lawActivityRoutes from './lawActivities';
import leadersRoutes from "./leader";
import liveRoutes from "./live";
import parlamentRoutes from './parlament';
import pagesRoutes from './pages';
import petitionsRoutes from './petitions';
import postsRoutes from './posts';
import pressRoutes from './press';
import managersRoutes from './managers';
import radioRoutes from './radio';
import usersRoutes from './users';
import votesRoutes from './votes';
import toragaRoutes from './toraga';


// middlewares
import auth from "@/middlewares/auth";
import authorized from "@/middlewares/authorized";
import autoRefresh from "@/middlewares/autorefreshPage";

const routeList = [
    {
        path: '',
        name: "home",
        component: ()=> import('@/views/_Home.vue'),
        meta: {translationFiles: ["home"]}
    },
    {
        path: 'search',
        name: "search",
        component: ()=> import('@/views/_Search.vue'),
        meta: {translationFiles: ["search"]},
    },
    {
        path: 'agenda',
        name: 'agenda',
        redirect: { name: 'agenda.list.calendar' },
        meta: {translationFiles: ["page", "agenda"]},
        children: agendaRoutes
    },
    {
        path: 'appeal',
        name: 'appeal',
        redirect: { name: 'appeal.send' },
        meta: {translationFiles: ['appeal']},
        children: appealRoutes
    },
    {
        path: 'bills',
        name: 'bills',
        redirect: { name: 'bills.index' },
        meta: {translationFiles: ["page", "bills"]},
        children: billsRoutes,
    },
    {
        path: 'chairman',
        name: 'leaders',
        redirect: { name: 'leaders.index' },
        meta: {translationFiles: ["page"]},
        children: leadersRoutes,
    },
    {
        path: 'committees',
        name: 'committees',
        redirect: { name: 'committees.index' },
        meta: {translationFiles: ["page", "committee"]},
        children: committeesRoutes,
    },
    {
        path: 'deputies',
        name: 'deputies',
        redirect: { name: 'deputies.index' },
        meta: {translationFiles: ["page", "deputy"]},
        children: deputiesRoutes,
    },
    {
        path: 'esi',
        name: 'esi',
        redirect: { name: 'esi.auth' },
        beforeEnter: authorized,
        children: esiRoutes
    },
    {
        path: 'factions',
        name: 'factions',
        redirect: { name: 'factions.index' },
        meta: {translationFiles: ["page", "faction"]},
        children: factionsRoutes,
    },
    {
        path: 'initiatives',
        name: 'initiatives',
        redirect: { name: 'initiatives.index' },
        meta: {translationFiles: ["page", "initiative"]},
        children: initiativesRoutes
    },
    {
        path: 'kenesh',
        name: 'kenesh',
        redirect: { name: 'kenesh.index', params: {category: "biznes-kenesh"}},
        meta: {translationFiles: ["page"]},
        children: keneshRoutes
    },
    {
        path: 'law-activity',
        name: 'law-activity',
        redirect: { name: 'law-activity.movements' },
        meta: {translationFiles: ["page", "law-activity"]},
        children: lawActivityRoutes
    },
    {
        path: 'live',
        name: 'live',
        redirect: { name: 'live.index' },
        children: liveRoutes
    },
    {
        path: 'pages',
        name: 'pages',
        redirect: { name: 'pages.show' },
        meta: {translationFiles: ["page"]},
        children: pagesRoutes
    },
    {
        path: 'posts',
        name: 'posts',
        redirect: { name: 'posts.index' },
        meta: {translationFiles: ["page", "post"]},
        children: postsRoutes,
    },
    {
        path: 'managers',
        name: 'managers',
        redirect: { name: 'managers.index' },
        meta: {translationFiles: ["page", "manager"]},
        children: managersRoutes
    },
    {
        path: 'parlament',
        name: 'parlament',
        redirect: { name: 'parlament.index' },
        meta: {translationFiles: ["page"]},
        children: parlamentRoutes
    },
    {
        path: 'petitions',
        name: 'petitions',
        redirect: { name: 'petitions.index' },
        meta: {translationFiles: ["page", "petition"]},
        children: petitionsRoutes
    },
    {
        path: 'press',
        name: 'press',
        redirect: { name: 'press.index' },
        meta: {translationFiles: ["page", "press"]},
        children: pressRoutes
    },
    {
        path: 'radio',
        name: 'radio',
        redirect: { name: 'radio.index' },
        meta: {translationFiles: ["page", "radio"]},
        children: radioRoutes,
    },
    {
        path: 'votes',
        name: 'votes',
        redirect: { name: 'votes.calendar' },
        meta: {translationFiles: ["page"]},
        children: votesRoutes
    },
    {
        path: 'toraga',
        name: 'toraga',
        redirect: { name: 'toraga.index' },
        meta: {translationFiles: ["page", "toraga"]},
        children: toragaRoutes,
    },
    {
        path: 'user',
        name: 'user',
        redirect: { name: 'user.profile' },
        // beforeEnter: auth,
        meta: {translationFiles: ["page", "user", 'petition', 'appeal', 'bills']},
        children: usersRoutes
    },

    // Test
    {
        path: 'test',
        component: () => import('@/views/appeal/Show.vue')
    },
];

const routes = createRouter({
    mode: 'history',
    base: '/',
    history: createWebHistory(),
    routes: [
        {
            path: "/:locale?",
            beforeEnter: Trans.routeMiddleware,
            children: routeList
        },
        {
            path: "/:catchAll(.*)",
            name: 'notFound',
            component: () => import('@/views/_NotFound.vue'),
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        // If the hash is present in the URL, maintain the scroll position.
        if (to.hash) {
            return { top: 0 }
            // return { selector: to.hash }
        }

        // If a saved position is available, use it.
        if (savedPosition) {
            return { top: 0 }
            // return savedPosition
        }

        // Otherwise, scroll to the top.
        return { top: 0 }
    }
});

routes.beforeEach((to, from, next) => {
    const twoHoursInMilliSeconds = 7200000
    setInterval(autoRefresh, twoHoursInMilliSeconds);

    const locale = to.params.locale;
    if (locale === "kg") document.title = "Кыргыз Республикасынын Жогорку Кеңеши";
    else                 document.title = "Жогорку Кенеш Кыргызской Республики";

    // scroll top always 0
    window.scrollTo(0, 0);

    next();
});

/*routes.beforeResolve((to, from, next) => {
    const pathWithoutQuery = to.path.split('?')[0];
    const newPath = `/${Trans.getPersistedLocale()}${pathWithoutQuery}`;

    to.params.locale = Trans.getPersistedLocale();
    to.query.locale = Trans.getPersistedLocale();
    to.path = newPath;

    next();
});*/

export default routes;