export default [
    {
        path: '',
        name: 'radio.index',
        component: () => import('@/views/radio/Index.vue'),
		props: (route) => ({
			type: route.query.type || 'publications',
			pageNumber: parseInt(route.query.p) || 1
		})
    },
    {
        path: ':id',
        name: 'radio.show',
        component: () => import('@/views/radio/Show.vue')
    },
    {
        path: 'p/:page',
        name: 'radio.page',
        component: () => import('@/views/radio/Page.vue')
    },
];