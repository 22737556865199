import "bvi-fix";

// Bootstrap modules
require('bootstrap/js/dist/dropdown'); // used by: globally
require('bootstrap/js/dist/tab'); // used by: globally
require('bootstrap/js/dist/collapse'); // used by: globally

window.SERVER = __IS_DEV_SERVER__
    ? "http://localhost:8080"
    : "https://kenesh.kg";

window.API_SERVER = __IS_DEV_SERVER__
    ? "http://localhost:8080/api"
    : "https://kenesh.kg/api";

window.ESI_SERVER = "https://kenesh.kg/esi_server";

window.SED_SERVER = "https://kenesh.kg/sed";

window.RECAPTCHA_SITE_KEY = "6LfgrFcpAAAAAB1piv_KYEdelm7ek0P4TsRa5tEQ";