<template>
    <div class="card no-hover">
        <div class="card-body">
            <h5 class="card-title text-center">
                {{$t('user.personal_info')}}
            </h5>
            <div class="card-subtitle d-flex">
                <PhUser/>
                <a href="#">
                    {{props.user.family_name}} <br/>
                    {{props.user.given_name}} <br/>
                    {{props.user.middle_name}}
                </a>
            </div>
            <div class="card-subtitle d-flex">
                <PhIdentificationBadge/>
                <a href="#">
                    ПИН:
                    {{props.user.pin}}
                </a>
            </div>
            <div class="card-subtitle d-flex">
                <div><PhCalendarBlank/></div>
                <a href="#">
                    {{ $t('user.birthdate') }}:
                    {{props.user.birthdate}}
                </a>
            </div>
            <div class="card-subtitle d-flex">
                <PhPhone/>
                <a :href="`tel:+(996)-779-182-372`">
                    Телефон:
                    {{ props.user.phone_number }}
                </a>
            </div>
            <div class="card-subtitle d-flex">
                <div><PhEnvelopeSimple/></div>
                <a :href="`mailto:aiba@gmail.com`">
                    E-mail:
                    {{props.user.email}}
                </a>
            </div>
            <div class="card-subtitle d-flex">
                <div><PhGlobeSimple/></div>
                <a href="#">
                    {{ $t('user.citizenship') }}:
                    {{props.user.citizenship}}
                </a>
            </div>
<!--            <div v-show="showMore">
                <div class="card-subtitle d-flex">
                    <PhList/>
                    <a href="#">{{$t('user.extra_field')}} #1</a>
                </div>
                <div class="card-subtitle d-flex">
                    <PhList/>
                    <a href="#">{{$t('user.extra_field')}} #2</a>
                </div>
                <div class="card-subtitle d-flex">
                    <PhList/>
                    <a href="#">{{$t('user.extra_field')}} #3</a>
                </div>
                <div class="card-subtitle d-flex">
                    <PhList/>
                    <a href="#">{{$t('user.extra_field')}} #4</a>
                </div>
                <div class="card-subtitle d-flex">
                    <PhList/>
                    <a href="#">{{$t('user.extra_field')}} #5</a>
                </div>
            </div>
            <div class="card-subtitle d-flex my-4">
                <a @click="showMore=!showMore" class="btn btn-primary w-100">
                    <template v-if="!showMore" >{{$t('user.show_all_fields')}}</template>
                    <template v-if="showMore" >{{$t('user.hide_all_fields')}} </template>
                </a>
            </div>-->
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";
import {PhUser, PhList, PhPhone,
        PhCalendarBlank, PhEnvelopeSimple,
        PhIdentificationBadge, PhGlobeSimple} from "@phosphor-icons/vue";

const showMore = ref(false);
const props = defineProps({
    user: {
        type: Object,
        required: true
    }
})
</script>