<template>
    <div class="section-tools p-0">
        <div class="section-tools-wrapper d-flex flex-column flex-md-row">
            <div>
                <router-link
                    :to="{name: 'factions.members', params: { id: factionId } }"
                    class="d-block h-100 btn btn-light flex-fill"
                    :class="props.active === 'members' ? 'active' : ''"
                >
                    {{ $t('faction.members') }}
                </router-link>
            </div>
            <div>
                <router-link
                    :to="{name: 'factions.activities', params: { id: factionId } }"
                    class="d-block h-100 btn btn-light flex-fill"
                    :class="props.active === 'activities' ? 'active' : ''"
                >
                    {{ $t('faction.activities') }}
                </router-link>
            </div>
            <div>
                <router-link
                    :to="{name: 'factions.news', params: { id: factionId } }"
                    class="d-block h-100 btn btn-light flex-fill"
                    :class="props.active === 'news' ? 'active' : ''"
                >
                    {{ $t('faction.news') }}
                </router-link>
            </div>
            <div>
                <router-link
                    :to="{name: 'factions.appeals', params: { id: factionId } }"
                    class="d-block h-100 btn btn-light flex-fill"
                    :class="props.active === 'appeals' ? 'active' : ''"
                >
                    {{ $t('faction.appeals') }}
                </router-link>
            </div>
            <div>
                <router-link
                    :to="{name: 'factions.calendars', params: { id: factionId } }"
                    class="d-block h-100 btn btn-light flex-fill"
                    :class="props.active === 'calendars' ? 'active' : ''"
                >
                    {{ $t('faction.calendars') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    active: {
        type: String,
        default: "members",
        required: false
    },
    factionId: {
        type: Number,
        required: true
    },
});
</script>