export function observeDomChange(targetContainer, callback) {
    if (!targetContainer) {
        // console.error('function observeDomChange: target container not found');
        return;
    }

    const observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                callback(mutation.addedNodes);
            }
        }
    });

    observer.observe(targetContainer, { childList: true });
}

export function observeClassChange(targetContainer, callback) {
    if (!targetContainer) {
        // console.error('function observeStyleChange: target container not found');
        return;
    }

    // Создаем экземпляр MutationObserver и передаем функцию обратного вызова, которая будет выполняться при изменении
    const observer = new MutationObserver((mutationsList) => {
        // Проверяем каждую мутацию в mutationsList
        mutationsList.forEach((mutation) => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                callback(mutation);
            }
        });
    });

    observer.observe(targetContainer, { attributes: true, attributeFilter: ['class'] });
}