<template>
    <div class="modal fade print-hide" id="mailModal" tabindex="-1" aria-labelledby="mailModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="mailModalLabel">
                        {{ getLocaleText(Manager.page.titleKg, Manager.page.titleRu, 300) }}
                    </h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-html="getLocaleText(Manager.page.descriptionKg, Manager.page.descriptionRu)"></div>

                    <div class="form-check">
                        <input v-model="confirmed"
                               type="checkbox"
                               id="confirm-radio"
                               class="form-check-input">
                        <label for="confirm-radio" class="form-check-label">
                            {{ $t('_mail_agree') }}
                        </label>
                    </div>
                </div>
                <div class="modal-footer" v-if="confirmed">
                    <button type="button" class="btn btn-outline-primary"
                            data-bs-toggle="modal" data-bs-target="#mailText"
                    >
                        {{ $t('_mail_compose') }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal modal-lg fade print-hide" id="mailText" tabindex="-1" aria-labelledby="mailModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="mailTextLabel">
                        {{ $t('_mail_online') }}
                    </h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form id="mailModalForm" @submit.prevent="Manager.form.submit">
                    <div class="modal-body">
                        <p><small>{{ $t('_required') }}</small></p>
                        <div class="row">
                            <div class="col-md-4 mb-4">
                                <input type="text" class="form-control" name="surname" :placeholder="$t('_last_name')+' *'">
                            </div>
                            <div class="col-md-4 mb-4">
                                <input type="text" class="form-control" name="surname" :placeholder="$t('_first_name')+' *'">
                            </div>
                            <div class="col-md-4 mb-4">
                                <input type="text" class="form-control" name="patronymic" :placeholder="$t('_surname')+' *'">
                            </div>
                            <div class="col-md-6 mb-4">
                                <input type="text" class="form-control" name="gmail" :placeholder="$t('_email')+' *'">
                            </div>
                            <div class="col-md-6 mb-4">
                                <input type="text" class="form-control" name="surname" :placeholder="$t('_mail_subject')+' *'">
                            </div>
                            <div class="col-md-12 mb-4">
                                <textarea rows="5" class="form-control" name="surname" :placeholder="$t('_mail_text')+' *'"></textarea>
                            </div>
                            <div class="col-md-12">
                                <vue-recaptcha
                                    @verify="onRecaptchaVerified"
                                    @expired="onRecaptchaExpired"
                                    :sitekey="yourRecaptchaSiteKey"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">{{ $t('_cancel') }}</button>
                        <button type="submit" class="btn btn-primary disabled">{{ $t('_send') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>


<script setup>
import Swal from "sweetalert2";
import { VueRecaptcha } from 'vue-recaptcha';
import {onMounted, reactive, ref} from "vue";
import fetchData from "@/js/global/fetchData";
import getLocaleText from "@/js/global/getLocaleText";
import copyObject from "@/helpers/copyObject";
import {useRouter} from "vue-router";
import Trans from "@/i18n/translation";
import BootstrapModal from 'bootstrap/js/dist/modal';
import Swal2 from "sweetalert2"; // used by: globally

const confirmed = ref(false);
const router = useRouter();
const lang = Trans.currentLocale;

// Google Recaptcha
const yourRecaptchaSiteKey = window.RECAPTCHA_SITE_KEY;

const onRecaptchaVerified = (response) => {
    if(response){
        document.querySelector('#mailModalForm button[type=submit]').classList.remove('disabled');
    }
};
const onRecaptchaExpired = async () => {
    const errorText = lang === "kg"
        ? "<h5>Google Recaptcha мөөнөтү бүттү!</h5>"
        : "<h5>Истек срок Google Recaptcha!</h5>";

    document.querySelector('#mailModalForm button[type=submit]').classList.add('disabled');

    await Swal2.fire({
        icon: "error",
        html: errorText,
        showConfirmButton: true,
    });
};

const Manager = {
    page: reactive({}),
    async setPage(pageId) {
        const response = await Manager.api.getPage(pageId);
        copyObject(response, Manager.page);
    },
    mail: {
        confirmed: ref(false),
    },
    form: {
        submit() {
            Swal.fire({
                icon: "success",
                html: "<h5>Ваша письмо успешно отправлена!</h5>",
                showConfirmButton: true,
            });
            document.querySelector('#mailText .btn-close').click();
        }
    },
    api: {
        async getPage(id) {
            const url = API_SERVER + "/pages/" + id;
            return await fetchData(url);;
        }
    }
}

onMounted(async () => {
    const pageId = 'pravila-oformleniya-elektronnogo-pis-ma';
    await Manager.setPage(pageId);

    // const modalEl = document.getElementById('mailModal');
    // const modal = new BootstrapModal(modalEl);
    // modal.show();
});


</script>