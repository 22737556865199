<template>
    <desktop-header v-if="!isMobile"
                    :authorized="authorized"
                    :user="user"
                    :socials="Manager.socials"
                    :top-links="Manager.topLinks"
                    :live="Manager.live"
                    :menu-links="Manager.menuLinks"
        />
    <mobile-header v-if="isMobile"
                   :authorized="authorized"
                   :user="user"
                   :top-links="Manager.topLinks"
                   :menu-links="Manager.menuLinks"
                   :socials="Manager.socials"/>
</template>

<script setup>
    import DesktopHeader from "./DesktopHeader.vue";
    import MobileHeader from "./MobileHeader.vue";
    import {onMounted, ref, reactive} from "vue";
    import fetchPaginatedData from "@/js/global/fetchPaginatedData";
    import userAuthorized from "@/js/global/userAuthrozed";
    import {getCookie} from "@/helpers/cookieAssistant";
    import copyObject from "@/helpers/copyObject";

    const Manager = {
        topLinks: reactive([]),
        menuLinks: reactive([]),
        socials: reactive([]),
        live: reactive([]),

        catalog: {
            async loadTopLinks() {
                const {data} = await Manager.api.getTopLinks();
                if (data.length > 0) {
                    Manager.topLinks.push(...data[0].menus);
                }
            },
            async loadMenuLinks() {
                const {data} = await Manager.api.getMenuLinks();
                if (data.length > 0) {
                    Manager.menuLinks.push(...data[0].menus);
                }
            },
            async loadSocials() {
                const {data} = await Manager.api.getSocials();
                Manager.socials.push(...data);
            },
            async loadLiveLinks() {
                const {data} = await Manager.api.getLiveLinks();
                Manager.live.push(...data);
            },
        },
        api: {
            async getTopLinks() {
                const url = API_SERVER + "/menus";
                const params = { slug: "top" };
                return await fetchPaginatedData(url, params);
            },
            async getMenuLinks() {
                const url = API_SERVER + "/menus";
                const params = { slug: "main" };
                return await fetchPaginatedData(url, params);
            },
            async getSocials() {
                const url = API_SERVER + "/socials";
                return await fetchPaginatedData(url);
            },
            async getLiveLinks() {
                const url = API_SERVER + "/lives";
                const params = { status: "false", limit: 1, sortBy: "id", sortDirection: "desc" }; // test params when no live video exist
                return await fetchPaginatedData(url);
            },
        }
    }

    const isMobile = ref(window.innerWidth <= 992);
    const authorized = ref(userAuthorized());
    const user = reactive({});

    if (authorized.value === true) {
        const userInfo = JSON.parse(getCookie('userdata', true));
        copyObject(userInfo, user)
    }

    const updateIsMobile = () => {
        isMobile.value = window.innerWidth <= 992;
    };

    onMounted(async () => {
        await Manager.catalog.loadTopLinks();
        await Manager.catalog.loadMenuLinks();
        await Manager.catalog.loadSocials();
        await Manager.catalog.loadLiveLinks()

        window.addEventListener('resize', updateIsMobile);
    });
</script>