const sections = [
    {
        name: 'bills',
        translation: 'search.bills',
        dispatch: "search/fetchBills",
        component: "PostItem"
    },
    {
        name: 'bills_public',
        translation: 'search.bills_public',
        dispatch: "search/fetchBillsPublic",
        component: "PostItem"
    },
    {
        name: 'agenda',
        translation: 'search.agenda',
        dispatch: "search/fetchAgenda",
        component: "PostItem"
    },
    {
        name: 'news',
        translation: 'search.news',
        dispatch: "search/fetchNews",
        component: "PostItem"
    },
    {
        name: 'deputies',
        translation: 'search.deputies',
        dispatch: "search/fetchDeputies",
        component: "PostItem"
    }
];

export default sections;