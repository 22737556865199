<template>
    <span v-if="showMore">{{truncateText(str, props.length)}}</span>
    <span v-if="!showMore">{{str}}</span>

    <div class="my-2"></div>

    <template v-if="strIsLonger">
        <div class="btn-more" v-if="showMore" @click="showMore=false">{{ $t('_show_more') }}</div>
        <div class="btn-more" v-if="!showMore" @click="showMore=true">{{ $t('_show_less') }}</div>
    </template>
</template>

<script setup>
import {ref, watch} from "vue";
import truncateText from "@/helpers/truncateText";

const props = defineProps({
    str: {
        type: String,
        default: "This text will be truncated after 15 symbols",
        required: false
    },
    length: {
        type: Number,
        required: false,
        default: 15
    }
});

const str = ref ("");
const strIsLonger = ref(false);
const showMore = ref(strIsLonger);

watch(
    () => props.str,
    () => {
        str.value = props.str ?? "";
        strIsLonger.value = str.value.length > props.length;
    },
    {immediate: true}
);
</script>