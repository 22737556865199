export default [
    {
        path: '',
        name: 'managers.index',
        component: () => import('@/views/manager/Index.vue'),
    },
    {
        path: ':id',
        name: 'managers.show',
        component: () => import('@/views/manager/Show.vue'),
    },
];