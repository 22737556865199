<template>
    <div class="input-group custom-file-button">
        <label class="input-group-text" :for="props.id">
            <PhPaperclip/>
            {{$t('_attach_files')}}
        </label>
        <input
            @change="handleFileChange"
            class="form-control form-file"
            type="file"
            :id="props.id"
            :data-empty-text="$t('_input_empty')"
            multiple>
    </div>
</template>

<script setup>
import { PhPaperclip } from "@phosphor-icons/vue";
import { onMounted } from "vue";

const props = defineProps({
    modelValue: {
        type: String,
        required: true
    },
    id: {
        type: String,
        required: true
    }
});
const emit = defineEmits();

const handleFileChange = (event) => {
  const files = Array.from(event.target.files);
  emit('update:modelValue', files);
};

onMounted( async () => {
  // File Input
  const fileInputs = document.querySelectorAll('.form-file');
  if(fileInputs && fileInputs.length > 0){
    fileInputs.forEach(fileInput => {
      fileInput.addEventListener('change', function handleClick(event) {
        if (fileInput.files.length > 0) {
          let resultText = '';
          for (let i = 0; i < fileInput.files.length; i++) {
            if(i+1 === fileInput.files.length){
              resultText += fileInput.files[i].name;
            } else {
              resultText += fileInput.files[i].name + ', ';
            }
          }
          fileInput.setAttribute('data-empty-text', resultText);
        } else {
          fileInput.setAttribute('data-empty-text', 'Ничего не выбрано');
        }
      });
    });
  }
})
</script>