export default [
    // Default news
    {
        path: '',
        name: 'posts.index',
        component: () => import('@/views/post/Index.vue'),
        props: (route) => ({ pageNumber: parseInt(route.query.p) || 1 })
    },
    {
        path: ':id',
        name: 'posts.show',
        component: () => import('@/views/post/Show.vue'),
    },

    // News
    {
        path: 'news',
        name: 'posts.news.index',
        component: () => import('@/views/post/Index.vue'),
        props: {transKey: 'post.news', category: 'news'}
    },
    {
        path: 'news/:id',
        name: 'posts.news.show',
        component: () => import('@/views/post/Show.vue'),
        props: {transKey: 'post.news', category: 'news'}
    },

    // Photo
    {
        path: 'photo',
        name: 'posts.photo.index',
        component: () => import('@/views/post/Index.vue'),
        props: {transKey: 'post.photo', categoryId: null, hasPhoto: true}
    },
    {
        path: 'photo/:id',
        name: 'posts.photo.show',
        component: () => import('@/views/post/Show.vue'),
        props: {transKey: 'post.photo', categoryId: null, hasPhoto: true}
    },

    // Video
    {
        path: 'video',
        name: 'posts.video.index',
        component: () => import('@/views/post/Index.vue'),
        props: {transKey: 'post.video', categoryId: null, hasVideo: true}
    },
    {
        path: 'video/:id',
        name: 'posts.video.show',
        component: () => import('@/views/post/Show.vue'),
        props: {transKey: 'post.video', categoryId: null, hasVideo: true}
    },

    // Международная деятельность
    {
        path: 'international-activity',
        name: 'posts.international-activity.index',
        component: () => import('@/views/post/Index.vue'),
        props: {
            transKey: 'post.category.international_activity',
            category: 'international-activity',
            categoryId: 302
        }
    },

    // Парламентский контроль
    {
        path: 'parliamentary-control',
        name: 'posts.parliamentary-control.index',
        component: () => import('@/views/post/Index.vue'),
        props: {
            transKey: 'post.category.parliamentary_control',
            category: 'parliamentary-control',
            categoryId: 303,
            list: true
        }
    },
];