import sections from "@/store/modules/search/data/sections";
import fetchData from "@/js/global/fetchData";
import fetchApiPaginatedData from "@/store/modules/bills/actions/fetchApiPaginatedData";

const state = {
    posts: [],
    pagination: {
        total: 0,
        page: 1,
        limit: 9
    }
};

const mutations = {
    SET_POSTS(state, posts) {
        if (Array.isArray(posts)) {
            state.posts = posts;
        }
    },
    SET_PAGINATION_TOTAL(state, total) {
        if (typeof total === 'number') {
            state.pagination.total = total;
        }
    },
    SET_PAGINATION_PAGE(state, page) {
        if (typeof page === 'number') {
            state.pagination.page = page;
        }
    },
    SET_KEYWORD(state, keyword) {
        if (typeof keyword === 'string') {
            state.keyword = keyword;
        }
    },
    RESET_PAGINATION(state) {
        state.posts = [];
        state.pagination.page = 1;
        state.pagination.total = 0;
        state.pagination.limit = 9;
    },
};

const actions = {
    async fetchStages() {
        const url = SED_SERVER + "/stages";
        return await fetchData(url);
    },
    async fetchResults() {
        const url = SED_SERVER + "/results";
        return await fetchData(url);
    },

    async fetchBills({ commit, state }) {
        const options = {
            commit,
            state,
            url: SED_SERVER + "/docs"
        };
        await fetchApiPaginatedData(options);
    },

    setPage({ commit }, newPage) {
        commit("SET_PAGINATION_PAGE", newPage);
    },
};

const getters = {
    getSections: () => sections,
    getKeyword: () => state.keyword,
    getPosts: state => state.posts,
    getPaginationTotal: state => state.pagination.total,
    getPaginationPage: state => state.pagination.page,
    getPaginationLimit: state => state.pagination.limit
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};