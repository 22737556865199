<template>
    <div id="mobile-header" class="mobile-header d-block d-lg-none print-hide pb-5">
        <div class="top-overlay"></div>
        <div class="burger-container">
            <div id="burger">
                <div class="bar topBar"></div>
                <div class="bar btmBar"></div>
            </div>
        </div>

        <div class="icon icon-logo">
            <router-link :to="{name: 'home'}" class="logo">
                <img :src="logo" alt="">
            </router-link>
        </div>

        <div class="icon icon-search">
            <div class="widget lang-select">
                <a
                    href="#"
                    data-lang="kg"
                    aria-label="Сайт на кыргызском"
                    title="Сайт на кыргызском"
                    role="button"
                    @click="switchLanguage"
                    class="lang-select__item"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    :class="{active: $i18n.locale === 'kg'}">
                    КЫР
                </a>
                <a
                    href="#"
                    data-lang="ru"
                    aria-label="Сайт на русском"
                    title="Сайт на русском"
                    role="button"
                    @click="switchLanguage"
                    class="lang-select__item"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    :class="{active: $i18n.locale === 'ru'}">
                    РУС
                </a>
            </div>
            <a class="search-form-toggle" @click="showSearchForm">
                <PhMagnifyingGlass class="search-form-open text-black"/>
            </a>
            <div class="widget sign-in">
                <PhUser/>
                <router-link v-if="props.authorized" :to="{name: 'user'}">
                    {{props.user.given_name}}
                </router-link>
                <router-link v-else :to="{name: 'esi'}">
                    {{$t('_sign_in')}}
                </router-link>
            </div>
        </div>

        <ul class="menu list-unstyled accordion">
<!--            <div class="widget lang-select justify-content-center">-->
<!--                <a-->
<!--                    role="button"-->
<!--                    aria-label="Сайт на кыргызском языке"-->
<!--                    title="Сайт кыргызча"-->
<!--                    data-lang="kg"-->
<!--                    @click="switchLanguage"-->
<!--                    class="lang-select__item"-->
<!--                    :class="{active: $i18n.locale === 'kg'}">-->
<!--                    КЫР-->
<!--                </a>-->
<!--                <a-->
<!--                    role="button"-->
<!--                    aria-label="Сайт на кыргызском языке"-->
<!--                    title="Сайт на русском"-->
<!--                    data-lang="ru"-->
<!--                    @click="switchLanguage"-->
<!--                    class="lang-select__item"-->
<!--                    :class="{active: $i18n.locale === 'ru'}">-->
<!--                    РУС-->
<!--                </a>-->
<!--            </div>-->

            <li class="menu-item" v-for="(menu, index) in props.menuLinks">
                <a v-if="menu.children.length > 0" :id="`navLink${index}`"  data-bs-toggle="collapse" :data-bs-target="`#navMenu${index}`">
                    {{ getLocaleText(menu.titleKg, menu.titleRu) }}
                    <PhCaretDown v-if="menu.children.length > 0" weight="bold"/>
                </a>
                <a v-else :id="`navLink${index}`" :href="menu.link">
                    {{ getLocaleText(menu.titleKg, menu.titleRu) }}
                </a>
                <div v-if="menu.children.length > 0" :id="`navMenu${index}`" class="collapse">
                    <ul class="list-unstyled">
                        <li v-for="menuChild in menu.children" class="ps-3">
                            <a :href="menuChild.link" class="cab-link cab-link-light">
                                {{ getLocaleText(menuChild.titleKg, menuChild.titleRu) }}
                            </a>
                        </li>
                    </ul>
                </div>
            </li>

            <li class="menu-item" v-for="menu in props.topLinks">
                <a :href="menu.link" class="cab-link cab-link-light">
                    {{ getLocaleText(menu.titleKg, menu.titleRu) }}
                </a>
            </li>

            <div class="widgets mt-4">
                <div class="widget mt-2">
                    <a @click.prevent="mountMailModal" class="text-light cursor-pointer">
                        <PhAt style="font-size: 20px"/>
                        <span class="ms-3">Эл.почта: kattar@kenesh.kg</span>
                    </a>
                </div>
                <div class="widget mt-2">
                    <a href="/live" class="text-light">
                        <PhBroadcast style="font-size: 20px"/>
                        <span class="ms-3">{{$t('_live')}}</span>
                    </a>
                </div>
                <div class="widget mt-2">
                    <a href="/radio" class="text-light">
                        <PhRadio style="font-size: 20px"/>
                        <span class="ms-3">{{$t('_radio')}}</span>
                    </a>
                </div>
                <div class="widget mt-2">
                    <a href="https://www.youtube.com/@jogorkukenesh2022" class="text-light">
                        <PhTelevisionSimple style="font-size: 20px"/>
                        <span class="ms-3">Парламент ТВ</span>
                    </a>
                </div>
            </div>

            <li class="mt-4">
                <div class="social-icons d-flex align-items-center">
                    <a :href="social.link" v-for="social in props.socials" target="_blank">
                        <PhFacebookLogo v-if="social.type.toLowerCase() === 'facebook'" />
                        <PhTwitterLogo v-if="social.type.toLowerCase() === 'twitter'" />
                        <PhYoutubeLogo v-if="social.type.toLowerCase() === 'youtube'" />
                        <PhInstagramLogo v-if="social.type.toLowerCase() === 'instagram'" />
                        <PhTelegramLogo v-if="social.type.toLowerCase() === 'telegram'" />
                    </a>
                </div>
            </li>

<!--            <div class="widget sign-in mt-4">
                <a href="/user/profile" class="d-block mx-auto text-white">
                    {{$t('_profile')}}
                </a>
            </div>-->
        </ul>

        <form @submit.prevent="submit" class="search-form" ref="searchForm">
            <div class="search-form-outer">
                <div class="search-form-wrapper">
                    <div class="search-form-inner">
                        <PhMagnifyingGlass class="text-black"/>
                        <input type="text"
                               :placeholder="$t('_search')"
                               v-model="formData.keyword"
                               class="form-control"
                               autocomplete="off">
                        <a class="search-form-close me-5" @click.prevent="submit">
                            <PhMagnifyingGlass/>
                        </a>
                        <a class="search-form-close" @click="closeSearchForm">
                            <PhX/>
                        </a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup>
import {
    PhFacebookLogo, PhTwitterLogo, PhYoutubeLogo,
    PhInstagramLogo, PhTelegramLogo, PhCaretDown,
    PhUser, PhMagnifyingGlass, PhX, PhAt,
    PhBroadcast, PhRadio, PhTelevisionSimple
} from "@phosphor-icons/vue";
import logo from "@/assets/img/logo/logo-mobile-beta.webp";
import BootstrapCollapse from "bootstrap/js/dist/collapse";

import Trans from "@/i18n/translation";
import getLocaleText from "@/js/global/getLocaleText";
import {ref, onMounted, inject, reactive} from "vue";
import {useRoute, useRouter} from "vue-router";

const route = useRoute();
const router = useRouter();
const searchForm = ref(null);
const mountMailModal = inject('mountMailModal');

const props = defineProps({
    authorized: {
        type: Boolean,
        required: true
    },
    user: {
        type: Object,
        required: true
    },
    menuLinks: {
        type: Object,
        required: true
    },
    topLinks: {
        type: Object,
        required: true,
    },
    socials: {
        type: Object,
        required: true
    }
});

const formData = reactive({
    keyword: '',
});

const switchLanguage = async (event) => {
    event.preventDefault();

    const target = event.target.closest('.lang-select__item');
    const newLocale = target.dataset.lang;

    if (!newLocale) return;

    await Trans.switchLanguage(newLocale, route);

    try {
        await router.replace({ params: { locale: newLocale } })
    } catch(e) {
        console.log("switchLanguage error catch", e);
        await router.push("/");
    }
}

onMounted(async () => {
    let header = document.querySelector('.mobile-header'),
        burger = document.querySelector('.burger-container');

    burger.addEventListener('click', function () {
        document.body.classList.toggle('overflow-hidden');
        header.classList.toggle('menu-opened');
    });

    let collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))
    collapseElementList.map(function (collapseEl) {
        return new BootstrapCollapse(collapseEl)
    });
})

const submit = async () => {
    await router.push({
        name: 'search',
        query: { keyword: formData.keyword }
    });
    closeSearchForm();
};


function showSearchForm() {
    searchForm.value.classList.add('active');
}

function closeSearchForm() {
    searchForm.value.classList.remove('active');
}
</script>
