import Trans from "@/i18n/translation";

export default function getLocaleText (textKg, textRu, length = Number.POSITIVE_INFINITY) {
    const lang = Trans.currentLocale;
    let title = lang === 'kg' ? textKg : textRu;

    // если заголовок за выбранный язык пустой то взять заголовок другого языка
    if (lang === "kg" && !title) title = textRu;
    if (lang === "ru" && !title) title = textKg;

    if (typeof title !== 'string') {
        return "";
    }

    return title.length > length ? `${title.slice(0, length)}...` : title;
}