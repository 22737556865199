document.addEventListener('mouseup', (e) => {
    const mainNavContainer = document.querySelector('.main-nav__container');

    if (mainNavContainer && !mainNavContainer.contains(e.target)) {
        mainNavContainer.style.height = '0';
        mainNavContainer.classList.remove('show');
    }
});

document.addEventListener('scroll', e => {
    const mainNavContainer = document.querySelector('.main-nav__container');

    if (mainNavContainer) {
        mainNavContainer.style.height = '0';
        mainNavContainer.classList.remove('show');
    }
});
