export default [
    {
        path: '',
        name: 'factions.index',
        component: () => import('@/views/faction/Index.vue'),
    },
    {
        path: ':id',
        name: 'factions.members',
        component: () => import('@/views/faction/Members.vue'),
    },
    {
        path: ':id/calendars',
        name: 'factions.calendars',
        component: () => import('@/views/faction/Calendar.vue'),
        props: (route) => ({ pageNumber: parseInt(route.query.p) || 1 })
    },
    {
        path: ':id/activities',
        name: 'factions.activities',
        component: () => import('@/views/faction/Activities.vue'),
        props: (route) => ({ pageNumber: parseInt(route.query.p) || 1 })
    },
    {
        path: ':id/news',
        name: 'factions.news',
        component: () => import('@/views/faction/News.vue'),
        props: (route) => ({ pageNumber: parseInt(route.query.p) || 1 })
    },
    {
        path: ':id/appeals',
        name: 'factions.appeals',
        component: () => import('@/views/faction/Appeal.vue'),
        props: (route) => ({ pageNumber: parseInt(route.query.p) || 1 })
    },
];