import fetchPaginatedData from "@/js/global/fetchPaginatedData";

export default async function fetchApiData({commit, state, url, sortBy, sortDirection}) {

    if (state.keyword.trim().length < 1) {
        return;
    }

    const params = {
        query: state.keyword,
        page: state.pagination.page - 1,
        limit: state.pagination.limit,
        sortBy,
        sortDirection,
    };

    const { header, data } = await fetchPaginatedData(url, params);

    commit("SET_PAGINATION_TOTAL", header.totalCount);
    commit("SET_POSTS", data);
}