<template>
    <div class="modal modal-lg fade" id="appealModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="appealModalLabel">
                        {{$t('_appeal_type')}}
                    </h1>
                    <button id="appealModalClose"
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"/>
                </div>
                <div class="modal-body text-center">
                    <div class="section-modules px-md-3 py-5">
                        <div class="row gy-4 gy-lg-0">
                            <div class="col-12 col-lg-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h5>{{$t('_authorized')}}</h5>
                                        <PhShieldCheck weight="light"/>
                                        <a @click.prevent="gotoAppealPage(true)" class="stretched-link"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h5>{{$t('_unauthorized')}}</h5>
                                        <PhLock weight="light"/>
                                        <a @click.prevent="gotoAppealPage(false)" class="stretched-link"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { PhShieldCheck, PhLock } from "@phosphor-icons/vue";
import { useRouter } from 'vue-router'; // импортируем useRouter из Vue Router
const router = useRouter(); // создаем объект маршрутизатора

function gotoAppealPage(forAuthroized = false) {
    document.getElementById('appealModalClose').click();
    router.push({name: 'appeal', query: {auth: forAuthroized}});
}
</script>