export default [
    {
        path: '',
        name: 'petitions.index',
        component: () => import('@/views/petition/Index.vue'),
    },
    {
        path: ':id',
        name: 'petitions.show',
        component: () => import('@/views/petition/Show.vue'),
    },
    {
        path: 'submit',
        name: 'petitions.submit',
        component: () => import('@/views/petition/Submit.vue'),
    },
];