<template>
    <li class="breadcrumb-item">
        <router-link :to="props.to">
            {{props.title}}
        </router-link>
    </li>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        required: true
    },
    to: {
        default: "#",
        required: false
    }
});
</script>