import i18n from "./index";
import { nextTick } from "vue";

const Trans = {
    get defaultLocale() {
        return "kg";
    },

    get currentLocale() {
        return i18n.global.locale.value;
    },

    set currentLocale(newLocale) {
        i18n.global.locale.value = newLocale;
    },

    getPersistedLocale() {
        const persistedLocale = sessionStorage.getItem("user-locale");

        if(persistedLocale === "kg" || persistedLocale === "ru") {
            return persistedLocale;
        } else {
            return Trans.defaultLocale;
        }
    },

    async switchLanguage(newLocale, route) {
        await Trans.loadLocaleMessages(newLocale, route);
        Trans.currentLocale = newLocale;
        document.querySelector("html").setAttribute("lang", newLocale);
        sessionStorage.setItem("user-locale", newLocale);
    },

    async loadLocaleMessages(locale, route) {
        const messages = {};
        const globalTranslationFiles = [
            "agenda", "appeal", "law-activity",
            "bills", "committee", "deputy",
            "faction", "global", "home",
            "initiative", "page", "petition",
            "post", "press", "radio", "search",
            "user", 'toraga', 'comments'];

        const routeTranslationFiles = route.meta.translationFiles || [];
        const translationFiles = [...globalTranslationFiles, ...routeTranslationFiles];

        for (const fileName of translationFiles) {
            const fileMessages = await import(`./locales/${locale}/${fileName}.json`);
            Object.assign(messages, fileMessages.default);
        }

        i18n.global.setLocaleMessage(locale, messages);

        return nextTick();
    },

    async routeMiddleware(to, from, next) {
        let lang = to.params.locale
                    ? to.params.locale
                    : Trans.getPersistedLocale();

        if(lang !== "kg" && lang !== "ru") {
            lang = Trans.defaultLocale;
        }

        await Trans.switchLanguage(lang, to);

        return next();
    },

    i18nRoute(to) {
        return {
            ...to,
            params: {
                locale: Trans.currentLocale,
                ...to.params
            }
        }
    }
}

export default Trans;