export default [
    {
        path: '',
        name: 'committees.index',
        component: () => import('@/views/committee/Index.vue'),
    },
    {
        path: ':id/members',
        name: 'committees.members',
        component: () => import('@/views/committee/Member.vue'),
    },
    {
        path: ':id/agenda',
        name: 'committees.agenda',
        component: () => import('@/views/committee/Agenda.vue'),
        props: (route) => ({ pageNumber: parseInt(route.query.p) || 1 })
    },
    {
        path: ':id/bills',
        name: 'committees.bills',
        component: () => import('@/views/committee/Bills.vue'),
        props: (route) => ({ pageNumber: parseInt(route.query.p) || 1 })
    },
    {
        path: ':id/news',
        name: 'committees.news',
        component: () => import('@/views/committee/News.vue'),
        props: (route) => ({ pageNumber: parseInt(route.query.p) || 1 })
    },
    {
        path: ':id/acts',
        name: 'committees.acts',
        component: () => import('@/views/committee/Acts.vue'),
        props: (route) => ({ pageNumber: parseInt(route.query.p) || 1 })
    },
    {
        path: ':id/groups',
        name: 'committees.groups',
        component: () => import('@/views/committee/Group.vue'),
    },
];