export default [
   {
        path: 'auth',
        name: 'esi.auth',
        component: () => import('@/views/esi/Auth.vue'),
    },
    {
        path: 'auth/success',
        name: 'esi.auth.success',
        component: () => import('@/views/esi/AuthSuccess.vue'),
    },
    {
        path: 'permission',
        name: "esi.permission",
        component: ()=> import('@/views/esi/Permission.vue')
    },
];