export default [
    {
        path: '',
        name: 'initiatives.index',
        component: () => import('@/views/initiative/Index.vue'),
    },
    {
        path: 'submit',
        name: 'initiatives.submit',
        component: () => import('@/views/initiative/Submit.vue'),
    },
    {
        path: ':id',
        name: 'initiatives.show',
        component: () => import('@/views/initiative/Show.vue'),
    },
];