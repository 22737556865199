<template>
    <header id="desktop-header" class="d-none d-lg-block fixed-top print-hide">
        <DesktopHeaderTop   :socials="props.socials"
                            :top-links="props.topLinks"
                            :user="props.user"
                            :authorized="props.authorized"
        />
        <DesktopHeaderNav   :menu-links="props.menuLinks"
                            :live="props.live"/>
    </header>
</template>

<script setup>
import DesktopHeaderTop from "./DesktopHeaderTop.vue";
import DesktopHeaderNav from "./DesktopHeaderNav.vue";
import {observeClassChange} from "@/helpers/observeDom";
import {watch} from "vue";
import {useI18n} from 'vue-i18n';

const {locale} = useI18n();

const props = defineProps({
    authorized: {
        type: Boolean,
        required: true
    },
    user: {
        type: Object,
        required: true
    },
    socials: {
        type: Object,
        required: true
    },
    topLinks: {
        type: Object,
        required: true,
    },
    menuLinks: {
        type: Object,
        required: true
    },
    live: {
        type: Object,
        required: true
    },
});


function addTopOffsetIfBviActive() {
    const header = document.getElementById('desktop-header');
    const bviActive = document.body.classList.contains('bvi-active');
    const bviPanel = document.querySelector('.bvi-panel');

    if (!header) {
        return;
    }

    if (bviActive) {
        header.style.top = bviPanel.offsetHeight + "px";
    }
    else {
        header.style.top = '0';
    }
}

function bviLocalization() {
    const bviPanel = document.querySelector('.bvi-panel');

    if (bviPanel) {
        // Font Size
        const bviBlockTitle1 = document.querySelector('.bvi-blocks > .bvi-block:first-child .bvi-block-title');
        // Colors
        const bviBlockTitle2 = document.querySelector('.bvi-blocks > .bvi-block:nth-child(2) .bvi-block-title');
        // Colors
        const bviBlockTitle3 = document.querySelector('.bvi-blocks > .bvi-block:nth-child(3) .bvi-block-title');
        // Settings
        const bviBlockTitle4 = document.querySelector('.bvi-blocks > .bvi-block:nth-child(5) .bvi-block-title');
        bviBlockTitle4.textContent = '&nbsp;';
        bviBlockTitle4.style.opacity = '0';
        // Regular Version
        const bviLinkRegularVersion = document.querySelector('[data-bvi="close"]');

        if(locale.value === 'ru'){
            bviBlockTitle1.textContent = 'Размер шрифта';
            bviBlockTitle2.textContent = 'Цвета сайта';
            bviBlockTitle3.textContent = 'Изображения';
            bviLinkRegularVersion.textContent = 'Обычная версия сайта';
        } else {
            bviBlockTitle1.textContent = 'Шрифттин өлчөмү';
            bviBlockTitle2.textContent = 'Түстөр';
            bviBlockTitle3.textContent = 'Сүрөттөр';
            bviLinkRegularVersion.textContent = 'Кадимки версия';
        }
    }
}

function normalizeHeaderTop() {
    addTopOffsetIfBviActive();
    bviLocalization();
    observeClassChange(
        document.querySelector('.bvi-panel'),
        addTopOffsetIfBviActive
    );
}

observeClassChange(document.body, normalizeHeaderTop);
window.addEventListener('scroll', normalizeHeaderTop);

watch(locale, () => {
    bviLocalization();
});
</script>