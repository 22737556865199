<template>
    <div class="card">
        <div class="card-img">
            <img loading="lazy" class="card-img-top bvi-img"
                :src="imageUrl"
                :alt="props.firstName + ' ' + props.lastName ">
        </div>
        <div class="card-body">
            <h5 class="card-title">
                {{props.lastName}}
                {{props.firstName}}
                {{props.surname}}
            </h5>
            <h6 class="card-subtitle" v-if="faction">
                {{$i18n.locale === 'kg' ? faction.titleKg : faction.titleRu}}
            </h6>
            <h6 class="card-subtitle mt-4" v-if="props.factionPosition">
                {{factionPosition}}
            </h6>
            <h6 class="card-subtitle mt-4" v-if="props.parliamentaryGroupPosition">
                {{parliamentaryGroupPosition}}
            </h6>
            <h6 class="card-subtitle mt-4" v-if="props.committeePosition">
                {{committeePosition}}
            </h6>
            <h6 class="card-subtitle mt-4" v-if="props.constituency">
                {{constituency}}
            </h6>
            <router-link :to="{name: 'deputies.show', params: { id: props.id } }"
                         :aria-label="props.lastName + props.firstName + props.surname"
                         class="stretched-link"/>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";
import getImagePath from "@/js/global/getImagePath";
import {useI18n} from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
    id: {
        type: Number,
        required: true
    },
    imageUrl: {
        type: String,
        required: false,
    },
    gender: {
        type: String,
        required: false,
    },
    imageQuality: {
        type: [String, null],
        default: null,
        required: false,
        validator: (value) => {
            return value === null || ['sm', 'md', 'hd'].includes(value);
        }
    },
    firstName: {
        type: String,
        required: false,
    },
    lastName: {
        type: String,
        required: false,
    },
    surname: {
        type: String,
        required: false,
    },
    factionPosition: {
        type: Boolean,
        required: false,
    },
    fractionLeader: {
        type: Boolean,
        required: false,
    },
    fractionLeaderAssistant: {
        type: Boolean,
        required: false,
    },
    committeePosition: {
        type: Boolean,
        required: false,
    },
    committeeChairman: {
        type: Boolean,
        required: false,
    },
    committeeChairmanAssistant: {
        type: Boolean,
        required: false,
    },
    parliamentaryGroupPosition: {
        type: Boolean,
        required: false,
    },
    headParliamentaryGroup: {
        type: Boolean,
        required: false,
    },
    deputyHeadParliamentaryGroup: {
        type: Boolean,
        required: false,
    },
    factions: {
        type: [Object, null],
        default: null,
        required: false,
    },
    constituency: {
        type: String,
        required: false,
    },
});

const imageUrl = computed(() => {
      if (props.imageQuality) {
          return getImagePath(props.imageQuality + '-' + props.imageUrl, "deputies", props.id);
      } else {
          return getImagePath(props.imageUrl, "deputies", props.id);
      }
  });

const faction = computed( () => {
    if (Array.isArray(props.factions) && props.factions.length > 0) {
        return {
            titleKg: props.factions[0].titleKg,
            titleRu: props.factions[0].titleRu,
        };
    }
    return null;
});

const factionPosition = computed( () => {
    if (props.fractionLeader) {
        return t('faction.leader');
    } else if (props.fractionLeaderAssistant) {
        return t('faction.leader_assistant');
    } else {}
    return t('faction.member');
});

const committeePosition = computed( () => {
    if (props.committeeChairman) {
      if(props.gender === 'FEMALE'){
        return t('committee.chairman_female');
      }
      return t('committee.chairman');
    } else if (props.committeeChairmanAssistant) {

        return t('committee.chairman_assistant');
    } else {}
    return t('committee.member');
});

const parliamentaryGroupPosition = computed( () => {
    if (props.headParliamentaryGroup) {
        return t('faction.headParliamentaryGroup');
    } else if (props.deputyHeadParliamentaryGroup) {
        return t('faction.deputyHeadParliamentaryGroup');
    } else {}
    return t('faction.memberParliamentaryGroup');
});
</script>