<template>
    <div class="page-actions">
        <div class="page-action-item">
            <router-link :to="props.toList" :class="{active: props.active === 'list'}">
                {{ $t('page.list') }} <PhListDashes weight="bold"/>
            </router-link>
        </div>
        <div class="page-action-item">
            <router-link :to="props.toCalendar" :class="{active: props.active === 'calendar'}">
                {{ $t('page.calendar') }} <PhCalendarBlank weight="bold"/>
            </router-link>
        </div>
    </div>
</template>

<script setup>
import {PhListDashes, PhCalendarBlank} from "@phosphor-icons/vue";

const props = defineProps({
    active: {
        type: String,
        default: "calendar"
    },
    toList: {
        default: "#",
        required: false
    },
    toCalendar: {
        default: "#",
        required: false
    }
})
</script>

<style scoped>
.page-actions .page-action-item {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.page-actions a{
    display: flex;
    margin: 5px 0;
    align-items: center;
}

.page-actions a:hover{
    text-decoration: underline;
}

.page-actions svg{
    display: block;
    margin-left: 10px;
    font-size: 24px;
}
.page-actions a:not(.active):not(.btn){
    /*color: $darkgrey-color;*/
    color: #888888;
}
</style>