import PostItem from './PostItem.vue';
import RadioItem from './RadioItem.vue';
import PostItemList from './PostItemList.vue';
import PostItemMedia from './PostItemMedia.vue';
import DeputyItem from './DeputyItem.vue';
import DeputyItemList from './DeputyItemList.vue';
import UserSidebar from './UserSidebar.vue';

// dumb components
import Breadcrumb from './Breadcrumb.vue';
import BreadcrumbItem from './BreadcrumbItem.vue';
import PageAction from './PageAction.vue';
import DeputyAction from './DeputyAction.vue';
import PageView from './PageView.vue';
import Pagination from './PageNavigation.vue';
import CustomFileInput from './CustomFileInput.vue';
import StrTruncate from './StrTruncate.vue';
import PhNewTwitterLogo from './PhNewTwitterLogo.vue';

import CommitteeHeader from './Headers/CommitteeHeader.vue'
import FactionHeader from './Headers/FactionHeader.vue'
import RadioHeader from './Headers/RadioHeader.vue'

const components = [
    { name: "PostItem", component: PostItem },
    { name: "PostItemList", component: PostItemList },
    { name: "PostItemMedia", component: PostItemMedia },
    { name: "RadioItem", component: RadioItem },
    { name: "DeputyItem", component: DeputyItem },
    { name: "DeputyItemList", component: DeputyItemList },
    { name: "UserSidebar", component: UserSidebar },

    // dumb components
    { name: "Breadcrumb", component: Breadcrumb },
    { name: "BreadcrumbItem", component: BreadcrumbItem },
    { name: "PageView", component: PageView },
    { name: "PageAction", component: PageAction },
    { name: "DeputyAction", component: DeputyAction },
    { name: "CustomFileInput", component: CustomFileInput },
    { name: "Pagination", component: Pagination },
    { name: "StrTruncate", component: StrTruncate },
    { name: "PhNewTwitterLogo", component: PhNewTwitterLogo },


    // headers
    { name: "CommitteeHeader", component: CommitteeHeader },
    { name: "FactionHeader", component: FactionHeader },
    { name: "RadioHeader", component: RadioHeader },
]

export default {
    install(app) {
        components.forEach(({name, component}) => {
            app.component(name, component);
        });
    }
}