<template>
    <li class="list-group-item d-flex align-items-center">
        <div class="list-group-item-leading d-flex flex-column align-items-center">
            <PhCalendarBlank/>
            <div class="post-info">
                <div class="post-widget text-center">
                    {{getDate(props.publishDate)}}
                    <br/>
                    {{getTime(props.publishDate)}}
                </div>
            </div>
        </div>
        <div class="list-body">
            <h4>{{ props.title }}</h4>
        </div>
        <router-link :to="props.to" class="stretched-link"/>
    </li>
</template>

<script setup>
import {PhCalendarBlank} from "@phosphor-icons/vue";
import getDate from "@/js/global/getDate";
import getTime from "@/js/global/getTime";

const props = defineProps({
    title: {
        type: String,
        required: false,
    },
    publishDate: {
        type: String,
        required: false
    },
    to: {
        default: "#",
        required: false
    }
});
</script>