<template>
    <ul class="pagination"
        :class="'justify-content-' + props.position"
        v-if="hideEmptyCase && pageItems.length > 1"
    >
        <li class="page-item" @click="pageChange(currentPage - 1)">
            <a class="page-link" href="#" aria-label="Предыдущая страница">
                <i class="ph-bold ph-caret-left"></i>
                <PhCaretLeft weight="bold"/>
            </a>
        </li>
        <li
            class="page-item"
            :class="{active: currentPage === page}"
            v-for="page in pageItems"
            :key="page"
            @click="pageChange(page)"
        >
            <a href="#" class="page-link"
               :aria-label="ariaLabel(currentPage === page, page)">
                {{page}}
            </a>
        </li>
        <li class="page-item" @click="pageChange(currentPage + 1)">
            <a class="page-link" href="#" aria-label="Следующая страница">
                <i class="ph-bold ph-caret-right"></i>
                <PhCaretRight weight="bold"/>
            </a>
        </li>
    </ul>
</template>

<script setup>
import "@/styles/pages/pagination.scss";
import {PhCaretLeft, PhCaretRight} from "@phosphor-icons/vue";
import {ref, reactive, watch, onMounted} from 'vue';
import generatePageItems from "@/helpers/generatePageItems";

const props = defineProps({
    position: {
        type: String,
        default: "center",
        required: false
    },
    totalCount: {
        type: Number,
        required: true
    },
    perPage: {
        type: Number,
        default: 10,
        required: false
    },
    currentPage: {
        type: Number,
        default: 1,
        required: false
    },
    hideEmptyCase: {
        type: Boolean,
        required: false,
        default: true
    }
});

const emit = defineEmits();
const pageItems = reactive([]);
const currentPage = ref(props.currentPage);

const getTotalPage = () => {
    return Math.ceil(props.totalCount / props.perPage);
}

const ariaLabel = (isActive, page) => {

    if (isNaN ( Number(page)) ) {
        return null;
    }

    if (isActive && page) {
        return "Страница №" + page + " (Текущая страница)";
    } else {
        return "Страница №" + page;
    }
}

function updatePageItems() {
    const generatedPageItems = generatePageItems(
        currentPage.value,
        getTotalPage()
    );
    pageItems.splice(0, pageItems .length, ...generatedPageItems);
}

const pageChange = (clickedPage) => {
    if (typeof clickedPage !== "number") {
        return;
    }
    if (clickedPage === currentPage.value) {
        return;
    }
    if (clickedPage < 1 || clickedPage > getTotalPage() ) {
        return;
    }

    currentPage.value = clickedPage;

    return emit('page-change', clickedPage);
}

watch(currentPage, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        updatePageItems();
    }
});

watch(() => props.currentPage, () => {
    currentPage.value = props.currentPage;
});

watch(() => props.totalCount, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        updatePageItems();
    }
});

onMounted(async () => {
    updatePageItems();
})
</script>