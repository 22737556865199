import { createApp } from "vue";
import App from "@/App.vue";
import store from "@/store";
import i18n from "@/i18n";


// global styles && scripts
import 'normalize.css';
import '@/styles/global/bootstrap.scss';
import '@/styles/global/_index.scss';
import "@/js/default.js";


// global components && routes
import appComponents from "@/components/global/_index";
import appRoutes from '@/router/_index';


// creating && setting
const app = createApp(App);
app.use(store);
app.use(i18n);
app.use(appComponents);
app.use(appRoutes);
app.mount("#app");