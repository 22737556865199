export default [
    {
        // законопроекты
        path: '',
        name: 'bills.index',
        component: () => import('@/views/bill/Index.vue'),
        props: (route) => ({ pageNumber: parseInt(route.query.p) || 1 })
    },
    {
        // законопроект (один)
        path: ':id',
        name: 'bills.show',
        component: () => import('@/views/bill/Show.vue'),
    },
    {
        // общественное обсуждение законопроектов
        path: 'public',
        name: 'bills.public',
        component: () => import('@/views/bill/Public.vue'),
        props: (route) => ({ pageNumber: parseInt(route.query.p) || 1 })
    },
    {
        // общественное обсуждение законопроекта (один)
        path: 'public/:id',
        name: 'bills.public.show',
        component: () => import('@/views/bill/PublicShow.vue'),
    },
]