<template>
    <li class="list-group-item d-flex align-items-center">
        <div class="list-group-item-leading d-flex flex-column align-items-center">
            <PhUser/>
        </div>
        <div class="list-body">
            <h4>{{props.fio}}</h4>
            <div class="list-desc">
                <p>{{props.faction}}</p>
                <p>{{props.committee}}</p>
            </div>
        </div>
        <router-link :to="{name: 'deputies.show', params: {id: props.id}}" class="stretched-link"/>
    </li>
</template>

<script setup>
import {PhUser} from "@phosphor-icons/vue";

const props = defineProps({
    id: {
        type: Number,
        required: true
    },
    fio: {
        type: String,
        required: false,
    },
    faction : {
        type: String,
        required: false,
    },
    committee : {
        type: String,
        required: false,
    }
});

</script>