export default [
    {
        path: '',
        name: 'leaders.index',
        component: () => import('@/views/leader/Index.vue'),
    },
    {
        path: ':id',
        name: 'leaders.show',
        component: () => import('@/views/leader/Show.vue'),
    }
];