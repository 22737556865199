<template>
    <div class="top-bar bg-primary">
        <div class="container">
            <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                <div class="left">
                    <div class="d-flex align-items-center">
                        <div class="social-icons d-flex">
                            <a :href="social.link" v-for="social in props.socials" target="_blank">
                                <PhFacebookLogo title="Жогорку Кенеш в facebook" v-if="social.type.toLowerCase() === 'facebook'" />
                                <PhNewTwitterLogo title="Жогорку Кенеш в twitter" v-if="social.type.toLowerCase() === 'twitter'"/>
                                <PhYoutubeLogo title="Жогорку Кенеш в youtube" v-if="social.type.toLowerCase() === 'youtube'" />
                                <PhInstagramLogo title="Жогорку Кенеш в instagram" v-if="social.type.toLowerCase() === 'instagram'" />
                                <PhTelegramLogo title="Жогорку Кенеш в telegram" v-if="social.type.toLowerCase() === 'telegram'" />
                            </a>
                        </div>
                        <!-- Skip to Content Link -->
                        <a href="#the-wrapper" class="sr-only sr-only-focusable">
                            Пропустить навигационное меню
                        </a>
                        <div class="divider light-divider"></div>
                        <div class="widgets ms-0">
                            <div class="widget">
                                <router-link :to="{ name: 'radio.index'}" title="Радио Парламент">
                                    <PhRadio/>
                                </router-link>
                            </div>
                            <div class="widget">
                                <a target="_blank" href="https://www.youtube.com/@jogorkukenesh2022" title="Парламент ТВ">
                                    <PhTelevisionSimple/>
                                </a>
                            </div>
                            <div class="widget">
                                <a title="Поиск по сайту" class="btn-search search-form-toggle" href="#">
                                    <PhMagnifyingGlass class="search-form-open"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="d-flex align-items-center">
                        <div class="links d-none d-xl-flex align-items-center">
                            <template v-for="(link, index) in props.topLinks">
                                <a :href="link.link">
                                    {{ getLocaleText(link.titleKg, link.titleRu) }}
                                </a>
                                <div class="divider light-divider" v-if="index !== props.topLinks.length - 1"></div>
                            </template>
                        </div>
                        <div class="widgets">
                            <div class="widget bvi-hide">
                               <a  id="bvi-btn"
                                   class="bvi-btn bvi-show"
                                   title="Версия для слабовидящих"
                                   data-bs-toggle="tooltip"
                                   data-bs-placement="bottom"
                                >
                                   <PhEye/>
                               </a>
                            </div>
                            <div class="widget lang-select">
                                <a
                                    href="#"
                                    data-lang="kg"
                                    aria-label="Сайт на кыргызском"
                                    title="Сайт на кыргызском"
                                    role="button"
                                    @click="switchLanguage"
                                    class="lang-select__item"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    :class="{active: $i18n.locale === 'kg'}">
                                    КЫР
                                </a>
                                <a
                                    href="#"
                                    data-lang="ru"
                                    aria-label="Сайт на русском"
                                    title="Сайт на русском"
                                    role="button"
                                    @click="switchLanguage"
                                    class="lang-select__item"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    :class="{active: $i18n.locale === 'ru'}">
                                    РУС
                                </a>
                            </div>

                            <div class="dropdown widget sign-in" v-if="props.authorized">
                                <a class="dropdown-toggle p-2"
                                   type="button"
                                   data-bs-toggle="dropdown"
                                   data-bs-auto-close="true"
                                   aria-expanded="false">
                                    <PhUser class="me-1"/>
                                    {{ props.user.given_name }}
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end">
                                    <li>
                                        <router-link :to="{name: 'user.profile'}" class="dropdown-item cursor-pointer">
                                            <PhUser class="me-1"/>
                                            {{ $t('user.profile') }}
                                        </router-link>
<!--                                        <router-link :to="{name: 'user.profile'}"  class="dropdown-item cursor-pointer">
                                            <PhChatCenteredDots class="me-1"/> Обращении
                                        </router-link>
                                        <router-link :to="{name: 'user.profile'}"  class="dropdown-item cursor-pointer">
                                            <PhChatsCircle class="me-1"/> Комментарии
                                        </router-link>-->
                                    </li>
                                    <li>
                                        <a @click.prevent="logout" class="dropdown-item cursor-pointer">
                                            <PhSignOut class="me-1"/> {{ $t('user.logout') }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="widget sign-in" v-if="!props.authorized">
                                <router-link :to="{name: 'esi'}"
                                             class="sign-in__item p-2"
                                             type="button"
                                             aria-label="Войти через ЕСИ"
                                >
                                    <PhUser class="me-1"/>
                                        {{$t('_sign_in_esi')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import "bvi-fix/dist/css/bvi.min.css";
    import {
        PhFacebookLogo, PhYoutubeLogo, PhUser,
        PhInstagramLogo, PhTelegramLogo, PhEye,
        PhRadio, PhTelevisionSimple,
        PhMagnifyingGlass, PhSignOut
    } from "@phosphor-icons/vue";

    import {onMounted} from "vue";
    import {useRoute, useRouter} from 'vue-router'
    import {useI18n} from "vue-i18n";
    import getLocaleText from "@/js/global/getLocaleText";
    import dropdownNormalize from "@/js/global/dropdownNormalize";
    import {deleteCookie, getCookie, hasCookie} from "@/helpers/cookieAssistant";
    import Trans from "@/i18n/translation";


    const route = useRoute();
    const router = useRouter();
    const {t, locale } = useI18n();

    const props = defineProps({
        authorized: {
            type: Boolean,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        socials: {
            type: Object,
            required: true
        },
        topLinks: {
            type: Object,
            required: true
        }
    });

    function logout() {
        deleteCookie('userdata');
        deleteCookie('code_verifier');
        deleteCookie('code_challenge');
        deleteCookie('state');

        // запрос на logout на ЕСИ
        if (hasCookie('_si.tkn')) {
            const idToken = getCookie('_si.tkn');
            deleteCookie('_si.tkn');
            location.href = 'https://esia.tunduk.kg/connect/endsession?id_token_hint=' + idToken + "&post_logout_redirect_uri=https://kenesh.kg";
        } else {
            // Если токен не найден, просто перезагрузить страницу
            location.href = "/";
        }

        location.href = "/";
    }

    const switchLanguage = async (event) => {
        event.preventDefault();
        const target = event.target.closest('.lang-select__item');
        const newLocale = target.dataset.lang;

        if (!newLocale) return;

        await Trans.switchLanguage(newLocale, route);

        try {
            await router.replace({ params: { locale: newLocale } })
        } catch(e) {
            console.log("switchLanguage error catch", e);
            await router.push("/");
        }
    }

    onMounted(async () => {
        new isvek.Bvi({
            target: '.bvi-btn',
            speech: false
        });

        dropdownNormalize();
    })
</script>

<style>
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
</style>