export default [
    // Показать
    {
        path: 'show/:id',
        name: 'agenda.show',
        component: () => import('@/views/agenda/Show.vue')
    },

    // 1. Календарный план
    {
        path: 'list/calendarniy-plan',
        name: 'agenda.list.calendar',
        component: () => import('@/views/agenda/List.vue'),
        props: (route) => ({
            transKey: 'agenda.calendar',
            type: 'calendar',
            pageNumber: parseInt(route.query.p) || 1
        })
    },
    {
        path: 'calendar/calendarniy-plan',
        name: 'agenda.calendar.calendar',
        component: () => import('@/views/agenda/Calendar.vue'),
        props: route => ({
            transKey: 'agenda.calendar',
            type: 'calendar',
            pageNumber: parseInt(route.query.p) || 1
        })
    },

    // 2. Поветски дня заседаний Жогорку Кеңеша
    {
        path: 'list/jogorku-kenesh',
        name: 'agenda.list.kenesh',
        component: () => import('@/views/agenda/List.vue'),
        props: route => ({
            transKey: 'agenda.kenesh',
            type: 'jogorku-kenesh',
            pageNumber: parseInt(route.query.p) || 1
        })
    },
    {
        path: 'calendar/jogorku-kenesh',
        name: 'agenda.calendar.kenesh',
        component: () => import('@/views/agenda/Calendar.vue'),
        props: route => ({
            transKey: 'agenda.kenesh',
            type: 'jogorku-kenesh',
            pageNumber: parseInt(route.query.p) || 1
        })
    },


    // 3. Повестки дня заседаний комитетов Жогорку Кенеша
    {
        path: 'list/committees',
        name: 'agenda.list.committees',
        component: () => import('@/views/agenda/List.vue'),
        props: route => ({
            transKey: 'agenda.committees',
            type: 'committees',
            committees: route.query.committees, // добавляем параметр committees из query
            pageNumber: parseInt(route.query.p) || 1
        }),
    },
    {
        path: 'calendar/committees',
        name: 'agenda.calendar.committees',
        component: () => import('@/views/agenda/Calendar.vue'),
        props: route => ({
            transKey: 'agenda.committees',
            type: 'committees',
            committees: route.query.committees, // добавляем параметр committees из query
            pageNumber: parseInt(route.query.p) || 1
        }),
    },

    // 4. Повестки дня заседаний фракций Жогорку Кенеша
    {
        path: 'list/factions',
        name: 'agenda.list.factions',
        component: () => import('@/views/agenda/List.vue'),
        props: route => ({
            transKey: 'agenda.factions',
            type: 'factions',
            factions: route.query.factions, // добавляем параметр factions из query
            pageNumber: parseInt(route.query.p) || 1
        }),
    },
    {
        path: 'calendar/factions',
        name: 'agenda.calendar.factions',
        component: () => import('@/views/agenda/Calendar.vue'),
        props: route => ({
            transKey: 'agenda.factions',
            type: 'factions',
            factions: route.query.factions, // добавляем параметр factions из query
            pageNumber: parseInt(route.query.p) || 1
        }),
    },
];