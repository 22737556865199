export default [
    {
        path: '',
        name: 'press.index',
        component: () => import('@/views/press/Index.vue'),
    },
    {
        path: 'accreditation',
        name: 'press.accreditation',
        component: () => import('@/views/press/Accreditation.vue'),
    },
];