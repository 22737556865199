export default [
    {
        path: '',
        name: 'deputies.index',
        component: () => import('@/views/deputy/Index.vue'),
        props: (route) => ({
            p: route.query.p,
            committees: route.query.committees,
            factions: route.query.factions,
        })
    },
    {
        path: 'list',
        name: 'deputies.list',
        component: () => import('@/views/deputy/List.vue'),
    },
    {
        path: ':id',
        name: 'deputies.show',
        component: () => import('@/views/deputy/Show.vue'),
    },
    {
        path: ':id/news',
        name: 'deputies.news',
        component: () => import('@/views/deputy/News.vue'),
    },
    {
        path: ':id/media',
        name: 'deputies.media',
        component: () => import('@/views/deputy/Media.vue'),
    },
    {
        path: 'constituency',
        name: 'deputies.constituency',
        component: () => import('@/views/deputy/Constituency.vue'),
    },
];