import Trans from "@/i18n/translation";

/**
 * Асинхронно отправляет запрос на сервер.
 * @param {string} host - URL сервера для отправки запроса.
 * @param {Object} [params={}] - Параметры запроса (может быть пустым объектом).
 * @param {string} [method='POST'] - Метод запроса (по умолчанию 'POST').
 * @returns {Promise<{ header: Object, data: Object }>} - Обещание с данными ответа от сервера
 */
export default async function fetchPaginatedData(host, params = {}, method = 'POST') {
    const url = new URL(host);
    params.lang = Trans.getPersistedLocale();
    url.search = new URLSearchParams(params).toString();

    try {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
            },
            method: method,
            mode: "cors", // no-cors, *cors, same-origin
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const responseJSON = await response.json();
        const header = {totalCount: responseJSON.totalElements};
        const data = responseJSON.content;

        return { header, data };
    }
    catch (error) {
        console.error('Fetch error:', error)
        return null;
    }
}