<template>
    <div class="section-tools p-0">
        <div class="section-tools-wrapper d-flex flex-column flex-md-row">
            <div>
                <router-link
                    :to="{name: 'committees.members', params: {id: committeeId}}"
                    class="d-block h-100 btn btn-light flex-fill"
                    :class="{active: props.active === 'members'}">
                    {{ $t('committee.members') }}
                </router-link>
            </div>
            <div>
                <router-link
                    :to="{name: 'committees.bills', params: {id: committeeId}}"
                    class="d-block h-100 btn btn-light flex-fill"
                    :class="{active: props.active === 'bills'}">
                    {{ $t('committee.bills') }}
                </router-link>
            </div>
            <div>
                <router-link
                    :to="{name: 'committees.news', params: {id: committeeId}}"
                    class="d-block h-100 btn btn-light flex-fill"
                    :class="{active: props.active === 'news'}">
                    {{ $t('committee.news') }}
                </router-link>
            </div>
            <div>
                <router-link
                    :to="{name: 'committees.acts', params: {id: committeeId}}"
                    class="d-block h-100 btn btn-light flex-fill"
                    :class="{active: props.active === 'acts'}">
                    {{ $t('committee.acts') }}
                </router-link>
            </div>
            <div v-if="props.friendshipGroups">
                <router-link
                    :to="{name: 'committees.groups', params: {id: committeeId}}"
                    class="d-block h-100 btn btn-light flex-fill"
                    :class="{active: props.active === 'groups'}">
                    {{ $t('committee.groups') }}
                </router-link>
            </div>
            <div>
                <router-link
                    :to="{name: 'committees.agenda', params: {id: committeeId}}"
                    class="d-block h-100 btn btn-light flex-fill"
                    :class="{active: props.active === 'agenda'}">
                    {{ $t('committee.agenda') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    committeeId: {
        type: String,
        required: true
    },
    friendshipGroups: {
        type: Boolean,
        required: false
    },
    active: {
        type: String,
        default: "members",
        required: false
    }
});
</script>