<template>
    <nav class="navbar navbar-expand-md">
        <div class="container">
            <router-link :to="{ name: 'home'}" class="navbar-brand" >
                <img src="@/assets/img/logo/logo-beta.png" alt="Логотип Жогорку Кенеша">
            </router-link>
            <div class="collapse navbar-collapse position-relative h-100" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item" v-for="(menu, index) in props.menuLinks" :key="index">
                        <a class="nav-link"
                           href="#"
                           v-if="menu.children.length > 0"
                           :data-target="`mainNav${index}`"
                           @click="showTargetNavMenu"
                        >
                        {{ getLocaleText(menu.titleKg, menu.titleRu) }}
                        <PhCaretDown
                            :data-target="`mainNav${index}`"
                            @click="showTargetNavMenu" weight="bold"
                        />
                        </a>
                        <router-link v-else class="nav-link" :to="menu.link" :tabindex="index + 1">
                            {{ getLocaleText(menu.titleKg, menu.titleRu) }}
                        </router-link>
                    </li>
                </ul>
                <a class="d-none d-lg-block d-xl-none p-0 text-danger btn-live broadcast__top"
                   :class="{broadcast: props.live.length > 0}"
                   @click="showTargetNavMenu"
                   data-target="mainNavLive">
                    <PhBroadcast size="24"/>
                </a>
                <a class="d-none d-xl-block btn btn-sm btn-outline-danger btn-live"
                   :class="{broadcast: props.live.length > 0}"
                   @click="showTargetNavMenu"
                   data-target="mainNavLive">
                    {{ $t('_live') }}
                </a>
                <form @submit.prevent="submit" class="search-form">
                    <div class="search-form-outer">
                        <div class="search-form-wrapper">
                            <div class="search-form-inner">
                                <PhMagnifyingGlass/>
                                <input type="text"
                                       :placeholder="$t('_search_hint')"
                                       v-model="formData.keyword"
                                       class="form-control" autocomplete="off">
                                <div class="search-form-actions d-flex align-items-center">
                                    <button type="submit" class="btn btn-outline-primary me-3">
                                        {{ $t('_search') }}
                                    </button>
                                    <a class="search-form-close" href="#">
                                        <PhX/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </nav>
    <div class="main-nav__container" style="overflow: auto !important;">
        <div class="container">
            <div v-for="(menu, index) in props.menuLinks"
                 :id="`mainNav${index}`"
                 class="main-nav__content d-none">
                <div class="main-nav__bg" :style="{ backgroundImage: `url(${imageMainNavBg})` }"></div>
                <div class="main-nav__inner">
                    <div class="row">
                        <div class="col-3 main-nav__left">
                            <h3>{{getLocaleText(menu.titleKg, menu.titleRu)}}</h3>
                        </div>
                        <div class="col-9 main-nav__right">
                            <ul class="list-unstyled">
                                <li v-for="menuChild in menu.children">
                                    <a :href="menuChild.link">
                                        {{getLocaleText(menuChild.titleKg, menuChild.titleRu)}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-nav__content d-none" id="mainNavLive">
                <div class="main-nav__bg" :style="{ backgroundImage: `url(${imageMainNavBg})` }"></div>
                <div class="main-nav__inner">
                    <div class="row justify-content-center">
                        <div v-for="live in props.live" :key="live.id" class="col-6 col-lg-4 main-nav__left">
                            <div class="card card-live border-0 bg-primary text-bg-dark">
                                <img :src="getYoutubeThumb(live.link)" class="card-img" alt="...">
                                <div class="card-img-overlay d-flex align-items-end">
                                    <h6 class="card-title align-items-end">
                                        <PhVideoCamera class="flex-shrink-0"/>
                                        <span>{{getLocaleText(live.title, live.titleRu)}}</span>
                                    </h6>
                                    <a
                                        :data-tile="live.title"
                                        :data-link="live.link"
                                        class="stretched-link"
                                        @click="showLiveModal(live.link, getLocaleText(live.title, live.titleRu))"
                                    ></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { PhCaretDown, PhMagnifyingGlass,PhVideoCamera, PhX, PhBroadcast} from "@phosphor-icons/vue";
import imageMainNavBg from "@/assets/img/main-nav-bg.webp";
import BootstrapModal from 'bootstrap/js/dist/modal'; // used by: globally

import {onMounted, reactive, nextTick, ref} from "vue";
import getYoutubeThumb from "@/helpers/getYoutubeThumb";
import getYouTubeVideoId from "@/helpers/getYoutubeVideoId";
import getLocaleText from "@/js/global/getLocaleText";
import {useRouter} from 'vue-router';
import '@/js/partials/header.js';


const router = useRouter();
const formData = reactive({keyword: ''});
const props = defineProps({
    menuLinks: {
        type: Object,
        required: true
    },
    live: {
        type: Object,
        required: true
    }
});

onMounted(async () => {
    // Поиск::START
    let navbarBrand = document.querySelector('.navbar-brand');
    let searchForm = document.querySelector('.search-form');
    let searchFormToggler = document.querySelector('.search-form-toggle');
    let searchFormInput = document.querySelector('.search-form input');
    let searchFormToggleShow = document.querySelectorAll('.search-form-open');
    let searchFormClose = document.querySelector('.search-form-close');

    // Add click event listener to searchFormToggleShow elements
    searchFormToggleShow.forEach(function (element) {
        element.addEventListener('click', function (e) {
            e.preventDefault();

            // Toggle 'active' class on searchForm
            if (searchForm.classList.contains('active')) {
                searchForm.classList.remove('active');
            } else {
                searchForm.classList.add('active');
            }

            // Toggle 'active' class on searchFormToggler
            if (searchFormToggler.classList.contains('active')) {
                searchFormToggler.classList.remove('active');
            } else {
                searchFormToggler.classList.add('active');
            }

            // Set focus on searchFormInput
            searchFormInput.focus();
        });
    });

    searchFormClose.addEventListener('click', function (e) {
        e.preventDefault();
        searchFormInput.value = '';

        searchForm.classList.remove('active');
    })

    navbarBrand.addEventListener('click', function (e) {
        searchFormInput.value = '';

        searchForm.classList.remove('active');
    })

    // Поиск::END
})

const submit = async () => {
    await router.push({
        name: 'search',
        query: { keyword: formData.keyword }
    });
};

function getAllSiblings(currentElement) {
    const parentElement = currentElement.parentElement;
    return Array.from(parentElement.children).filter(element => element !== currentElement);
}

function showTargetNavMenu (e) {
    e.preventDefault();

    if(e.target.classList.contains('btn-live')){
        if(localStorage.getItem('youtube-id')){
            let myModalAlternative = new BootstrapModal('#liveModal');
            myModalAlternative.show();
        }
    }
    showMainNavContainer(e);
}

function showMainNavContainer(e) {
    const navLink = e.target.closest('.btn-live') || e.target;
    const navLinkTarget = document.getElementById(navLink.dataset.target);
    const mainNavContainer = document.querySelector('.main-nav__container');

    if (!navLinkTarget) return;

    // Удаляем класс 'active' у всех соседей
    getAllSiblings(navLink).forEach(sibling => {
        sibling.classList.remove('active');
    });
    navLink.parentElement.classList.add('active');

    // Если элемент-меню скрыт, то показать его и скрыть всех соседних
    if (navLinkTarget.classList.contains('d-none')) {
        getAllSiblings(navLinkTarget).forEach(sibling => {
            sibling.classList.add('d-none');
        });


        navLinkTarget.classList.remove('d-none');
    }
    // Иначе скрыть показываемые элемент-меню и объертку для меню
    else {
        mainNavContainer.classList.remove('show');
        navLinkTarget.classList.add('d-none');
        navLink.parentElement.classList.remove('active');
    }

    mainNavContainer.classList.add('show');
    mainNavContainer.style.height = navLinkTarget.offsetHeight + 'px';
}

function showLiveModal(link, title) {
    let myModalAlternative = new BootstrapModal('#liveModal'),
		youtubeSrc = "https://www.youtube.com/embed/"+getYouTubeVideoId(link)+'?autoplay=1&rel=0';

    document.querySelector('#liveModal iframe').src = youtubeSrc;
    document.querySelector('#liveModal .modal-title').textContent = title;
    localStorage.setItem('youtube-id', true);1

	setTimeout(function() {
		myModalAlternative.show();
	}, 300)
}
</script>