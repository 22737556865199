<template>
    <footer class="print-hide">
        <div class="container">
            <div class="row gy-5">
                <div class="col-md-6 col-xl-3" v-for="(nav, index) in linkNavList">
                    <div class="footer-section">
                        <div class="footer-header">
                            {{ getLocaleText(nav.titleKg, nav.titleRu) }}
                        </div>
                        <div class="footer-body">
                            <ul>
                                <li v-for="navLink in nav.children">
                                    <a :href="navLink.link"
                                       class="cursor-pointer"
                                       :data-bs-toggle="navLink.slug === 'kattar@kenesh.kg' ? 'modal' : null"
                                       :data-bs-target="navLink.slug === 'kattar@kenesh.kg' ? '#mailText' : null">
                                        {{getLocaleText(navLink.titleKg, navLink.titleRu)}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright">
                <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                  <div>
                    {{new Date().getFullYear()}}
                    {{$t('_copyright') }}
                  </div>
                  <div id="netKGWidget" class="mt-3 mt-md-0">
                    <noscript>
                      <a href='http://www.net.kg/stat.php?id=7246&amp;fromsite=7246' target='_blank'><img
                        src="http://www.net.kg/img.php?id=7246" border='0' alt='WWW.NET.KG' width='88'
                        height='31' /></a>
                    </noscript>
                  </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
import {onMounted, reactive} from "vue";
import fetchPaginatedData from "@/js/global/fetchPaginatedData";
import getLocaleText from "@/js/global/getLocaleText";

const linkNavList = reactive([]);

onMounted(async() => {
    const response = await getLinks();
    if (response.data.length > 0 ){
        linkNavList.push(...response.data[0].menus);
    }

    let java = "1.3";
    let java1="";

    java1=""+"refer="+escape(document.referrer)+"&amp;page="+escape(window.location.href);
    document.cookie="astratop=1; path=/";
    java1+="&amp;c="+(document.cookie?"yes":"now");
    java1+="&amp;java="+(navigator.javaEnabled()?"yes":"now");
    java1+="&amp;razresh="+screen.width+'x'+screen.height+"&amp;cvet="+
    (((navigator.appName.substring(0,3)=="Mic"))?
      screen.colorDepth:screen.pixelDepth);
    java1+="&amp;jscript="+java+"&amp;rand="+Math.random();
    document.getElementById("netKGWidget").innerHTML = "<a href='http://www.net.kg/stat.php?id=7246&amp;fromsite=7246' target='_blank'>"+
    "<img src='http://www.net.kg/img.php?id=7246&amp;"+java1+
    "' border='0' alt='WWW.NET.KG' width='88' height='31' /></a>";
})

async function getLinks() {
    const url = API_SERVER + "/menus";
    const params = {slug: "footer"};
    return await fetchPaginatedData(url, params);
}
</script>
