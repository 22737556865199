export default [
    // Показать
    {
        path: ':type/:id',
        name: 'law-activity.show',
        component: () => import('@/views/law-activity/Show.vue')
    },

    // 1. Движение законопроектов
    {
        path: 'movements',
        name: 'law-activity.movements',
        component: () => import('@/views/law-activity/Index.vue'),
        props: route => ({
            transKey: 'law_activity.movements',
            type: 'movements',
            pageNumber: parseInt(route.query.p) || 1
        })
    },

    // 2. Участие депутатов в заседаниях комитетов Жогорку Кенеша
    {
        path: 'committees',
        name: 'law-activity.committees',
        component: () => import('@/views/law-activity/Index.vue'),
        props: route => ({
            transKey: 'law_activity.committees',
            type: 'committees',
            pageNumber: parseInt(route.query.p) || 1
        })
    },


    // 3. Участие депутатов в заседаниях Жогорку Кенеша
    {
        path: 'deputies',
        name: 'law-activity.deputies',
        component: () => import('@/views/law-activity/Index.vue'),
        props: route => ({
            transKey: 'law_activity.deputies',
            type: 'deputies',
            pageNumber: parseInt(route.query.p) || 1
        }),
    },

    // 4. Постановления Жогорку Кенеша
    {
        path: 'resolutions',
        name: 'law-activity.resolutions',
        component: () => import('@/views/law-activity/Index.vue'),
        props: route => ({
            transKey: 'law_activity.resolutions',
            type: 'resolutions',
            pageNumber: parseInt(route.query.p) || 1
        }),
    },

    // 5. Протоколы заседаний Жогорку Кенеша
    {
        path: 'protocols',
        name: 'law-activity.protocols',
        component: () => import('@/views/law-activity/Index.vue'),
        props: route => ({
            transKey: 'law_activity.protocols',
            type: 'protocols',
            pageNumber: parseInt(route.query.p) || 1
        }),
    },
];